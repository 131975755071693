import { useContext, useCallback } from 'react';
import { useMoralis } from 'react-moralis';
import { PartyContext } from '../context/partyContext';
import { SocketContext } from '../context/socketContext';

/**
 * Returns nothing. Just disconnects a user from all parties and shows they are in.
 *
 * @param {object} socket A reference to the currently opened socket
 */
const useSocketConnect = (showId) => {
  const { socket } = useContext(SocketContext);
  const { setAllParties } = useContext(PartyContext);
  const { user } = useMoralis();

  const connectSocket = useCallback(() => {
    if (!showId) return false;

    socket.emit('joinShow', {
      show: showId,
      username: user.get('username'),
      avatar: user.get('avatar')
    });

    socket.on('message', (message) => {
      console.log(message);
    });

    // socket.on('showUsers', (users) => {
    //   setShowAudience(users);
    // });

    socket.emit('listParties', {
      show: showId
    });

    socket.on('allParties', (message) => {
      setAllParties(message);
    });

    return true;
  }, [showId, socket, user, setAllParties]);

  return { connectSocket };
};

export default useSocketConnect;
