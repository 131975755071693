import React, { useContext } from 'react';
import styled from 'styled-components';
import Participant from './Participant';
import 'react-toastify/dist/ReactToastify.css';
import { PartyContext } from '../../context/partyContext';
import Colors from '../../constants/Colors';
import PartySlot from './PartySlot';
import Me from './Me';

const ParticipantContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Waiting = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 0.7em;
  text-align: center;
  padding: 1rem;
  user-select: none;
  color: ${Colors.lightestgrey};
`;

const ParticipantList = () => {
  const { participants } = useContext(PartyContext);

  return (
    <>
      <ParticipantContainer>
        <Me participantsCount={participants.length} />

        {participants.length !== 0 ? participants.map((participant) => (
          <Participant
            key={participant.sid}
            participant={participant}
            participantsCount={participants.length}
          />
        )) : null}

        {participants.length === 0
        && (
        <PartySlot>
          <Waiting>Waiting for others...</Waiting>
        </PartySlot>
        )}
      </ParticipantContainer>
    </>
  );
};

export default ParticipantList;
