import {
  useCallback, useState, useContext, useEffect
} from 'react';
import Twilio from 'twilio-video';
// import { createLocalTracks, createLocalVideoTrack, createLocalAudioTrack } from 'twilio-video';
import useDevices from './useDevices';
import { PartyContext } from '../context/partyContext';

const DEFAULT_VIDEO_CONSTRAINTS = {
  facingMode: 'user',
  width: 200,
  height: 200,
  // frameRate: 24
};

// These are used to store the selected media devices in localStorage
const SELECTED_AUDIO_INPUT_KEY = 'shomego-selectedAudioInput';
// const SELECTED_AUDIO_OUTPUT_KEY = 'shomego-selectedAudioOutput';
const SELECTED_VIDEO_INPUT_KEY = 'shomego-selectedVideoInput';

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState(undefined);
  const [videoTrack, setVideoTrack] = useState(undefined);
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);
  const {
    audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices
  } = useDevices();
  const {
    room, localTracks, setLocalTracks, setVideoOn, setAudioOn
  } = useContext(PartyContext);

  const enableLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.enable();
      // console.log('enabled audioTrack');

      if (room) {
        room.localParticipant.audioTracks.forEach((track) => {
          // console.log('telling room that this participant has ENABLED audio tracks');
          if (track) {
            track.track.enable();
          }
        });
      }

      setAudioOn(true);
    }
  }, [room, audioTrack, setAudioOn]);

  const enableLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.enable();
      // console.log('enabled videoTrack');

      if (room) {
        room.localParticipant.videoTracks.forEach((track) => {
          // console.log('telling room that this participant has ENABLED video tracks');
          if (track) {
            track.track.enable();
          }
        });
      }

      setVideoOn(true);
    }
  }, [room, videoTrack, setVideoOn]);

  const getLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      enableLocalAudioTrack();
      return Promise.resolve();
    }

    const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY)
    || audioInputDevices[0];

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );

    const options = {};

    if (hasSelectedAudioDevice) {
      options.deviceId = { exact: selectedAudioDeviceId };
    }

    return Twilio.createLocalAudioTrack(options).then((newTrack) => {
      setAudioTrack(newTrack);

      if (room) {
        // console.log('telling room that this participant has published an audio track');
        room.localParticipant.publishTrack(newTrack);

        room.localParticipant.audioTracks.forEach((track) => {
          // console.log('telling room that this participant has enabled audio tracks');
          if (track) {
            track.track.enable();
          }
        });
      }

      setAudioOn(true);

      return newTrack;
    }).catch((error) => {
      console.error('Error:', error);
    });
  }, [room, audioInputDevices, audioTrack, setAudioOn, enableLocalAudioTrack]);

  const getLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      enableLocalVideoTrack();
      return Promise.resolve();
    }

    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
    || videoInputDevices[0];

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    const options = {
      ...(DEFAULT_VIDEO_CONSTRAINTS),
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
    };

    return Twilio.createLocalVideoTrack(options).then((newTrack) => {
      setVideoTrack(newTrack);

      // console.log('created video track');

      if (room) {
        // console.log('telling room that this participant has published a video track');
        room.localParticipant.publishTrack(newTrack);

        room.localParticipant.videoTracks.forEach((track) => {
          // console.log('telling room that this participant has enabled video tracks');
          if (track) {
            track.track.enable();
          }
        });
      }

      setVideoOn(true);

      return newTrack;
    }).catch((error) => {
      console.error('Error:', error);
    });
  }, [room, videoInputDevices, videoTrack, setVideoTrack, setVideoOn, enableLocalVideoTrack]);

  const disableLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.disable();

      if (room) {
        room.localParticipant.audioTracks.forEach((track) => {
          // console.log('telling room that this participant has DISABLED audio tracks');
          if (track) {
            track.track.disable();
          }
        });
      }

      setAudioOn(false);
    }
  }, [room, audioTrack, setAudioOn]);

  const disableLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.disable();

      if (room) {
        room.localParticipant.videoTracks.forEach((track) => {
          // console.log('telling room that this participant has DISABLED video tracks');
          if (track) {
            track.track.disable();
          }
        });
      }

      setVideoOn(false);
    }
  }, [room, videoTrack, setVideoOn]);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);

      if (room) {
        room.localParticipant.audioTracks.forEach((track) => {
          if (track) {
            track.track.stop();
            track.unpublish();
          }
        });
      }

      setAudioOn(false);
    }
  }, [room, audioTrack, setAudioOn]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      // videoTrack.disable();
      setVideoTrack(undefined);

      if (room) {
        room.localParticipant.videoTracks.forEach((track) => {
          // console.log('telling room that this participant has REMOVED video tracks');
          if (track) {
            track.track.stop();
            track.unpublish();
          }
        });
      }

      setVideoOn(false);
    }
  }, [room, videoTrack, setVideoOn]);

  const getAudioAndVideoTracks = useCallback(() => {
    if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve();

    setIsAcquiringLocalTracks(true);

    const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    const localTrackConstraints = {
      video: hasVideoInputDevices && {
        ...(DEFAULT_VIDEO_CONSTRAINTS),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      },
      audio: hasSelectedAudioDevice
        ? { deviceId: { exact: selectedAudioDeviceId } }
        : hasAudioInputDevices,
    };

    return Twilio.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const foundVideoTrack = tracks.find((track) => track.kind === 'video');
        const foundAudioTrack = tracks.find((track) => track.kind === 'audio');

        if (foundVideoTrack) {
          setVideoTrack(foundVideoTrack);
        }
        if (foundAudioTrack) {
          setAudioTrack(foundAudioTrack);
        }
      }).catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, [
    hasAudioInputDevices,
    hasVideoInputDevices,
    audioTrack,
    videoTrack,
    audioInputDevices,
    videoInputDevices,
    isAcquiringLocalTracks,
  ]);

  const saveAudioInput = useCallback((deviceId) => {
    console.log(deviceId);
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, deviceId);
  }, []);

  const saveVideoInput = useCallback((deviceId) => {
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, deviceId);
  }, []);

  // const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined);
  useEffect(() => {
    // console.log('localTracks changed');
    const filteredTracks = [audioTrack, videoTrack].filter((track) => track !== undefined);
    setLocalTracks(filteredTracks);
  }, [audioTrack, videoTrack, setLocalTracks]);

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    enableLocalAudioTrack,
    enableLocalVideoTrack,
    disableLocalAudioTrack,
    disableLocalVideoTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    saveAudioInput,
    saveVideoInput
  };
}
