import React, {
  // useEffect,
  // useRef,
  createContext
} from 'react';
import io from 'socket.io-client';
import { useBeforeunload } from 'react-beforeunload';
import { useAuth0 } from '@auth0/auth0-react';

const socket = io(process.env.REACT_APP_SOCKETS, {
  reconnectionAttempts: 10
});

export const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const { user } = useAuth0();
  const uuidUri = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/uuid`;
  // const socket = useRef();

  // useEffect(() => {
  //   socket.current = io(process.env.REACT_APP_SOCKETS, { reconnectionAttempts: 10 });
  // }, [socket]);

  useBeforeunload(() => {
    if (user) {
      socket.disconnect(user[uuidUri]);
    } else {
      socket.disconnect();
    }
  });

  return (
    <SocketContext.Provider value={{
      socket
    }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
