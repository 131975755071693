import React from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';

const StyledInput = styled.input`
  background: ${Colors.grey};
  color: ${Colors.white};
  display: block;
  border: 0;
  margin: 0.5em 0;
  padding: 0.4em 0.8em;
  font-size: 0.9em;
  line-height: 1;
  border-radius: 0.5em;
  outline: none;
`;

const StyledTextarea = styled.textarea`
  background: ${Colors.grey};
  color: ${Colors.white};
  display: block;
  border: 0;
  margin: 0.5em 0;
  padding: 0.4em 0.8em;
  font-size: 0.9em;
  line-height: 1.4;
  border-radius: 0.5em;
  outline: none;
`;

const StyledSelect = styled.select`
  background: ${Colors.grey};
  color: ${Colors.white};
  display: block;
  border: 0;
  margin: 0.5em 0;
  padding: 0.4em 0.8em;
  font-size: 0.9em;
  line-height: 1.4;
  border-radius: 0.5em;
  outline: none;
`;

export const Select = ({
  placeholder, required, autofocus, onChange, onKeyDown, value, className, children
}) => (
  <StyledSelect
    placeholder={placeholder}
    onChange={onChange}
    onKeyDown={onKeyDown}
    className={className}
    required={required}
    autoFocus={autofocus}
    value={value}
  >
    {children}
  </StyledSelect>
);

export const Textarea = ({
  placeholder, required, autofocus, onChange, onKeyDown, value, className
}) => (
  <StyledTextarea
    placeholder={placeholder}
    onChange={onChange}
    onKeyDown={onKeyDown}
    className={className}
    required={required}
    autoFocus={autofocus}
    value={value}
  />
);

const Input = ({
  type = 'text',
  placeholder,
  required,
  autofocus,
  onChange,
  onKeyDown,
  value,
  defaultChecked,
  className
}) => (
  <StyledInput
    type={type}
    placeholder={placeholder}
    onChange={onChange}
    onKeyDown={onKeyDown}
    className={className}
    required={required}
    autoFocus={autofocus}
    value={value}
    defaultChecked={defaultChecked}
  />
);

export default Input;
