import React from 'react';
import styled from 'styled-components';
import { Label } from '../UI/Text';
import Input, { Textarea } from '../UI/Input';
import UploadCover from './UploadCover';

const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PosterColumn = styled.div`
  width: 40%;
`;

const DetailsColumn = styled.div`
  width: 55%;
`;

const InputContainer = styled.div`
  margin-bottom: 2em;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledTextarea = styled(Textarea)`
  width: 100%;
  height: 8em;
`;

const AdminVenueFields = ({
  username,
  setUsername,
  displayName,
  setDisplayName,
  description,
  setDescription,
  website,
  setWebsite,
  phone,
  setPhone,
  address,
  setAddress,
  ethAddress,
  setEthAddress,
  streamUrl,
  setStreamUrl,
  cover,
  setCover,
}) => {
  const handleUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleDisplayName = (event) => {
    setDisplayName(event.target.value);
  };

  const handleDescripion = (event) => {
    setDescription(event.target.value);
  };

  const handleWebsite = (event) => {
    setWebsite(event.target.value);
  };

  const handlePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleAddress = (event) => {
    setAddress(event.target.value);
  };

  const handleEthAddress = (event) => {
    setEthAddress(event.target.value);
  };

  const handleStreamUrl = (event) => {
    setStreamUrl(event.target.value);
  };

  return (
    <ColumnContainer>
      <PosterColumn>
        <UploadCover cover={cover} setCover={setCover} />
      </PosterColumn>

      <DetailsColumn>
        <InputContainer>
          <Label>
            Username:
          </Label>
          This will be used as the event slug
          <StyledInput
            type='text'
            onChange={handleUsername}
            value={username}
          />
        </InputContainer>

        <InputContainer>
          <Label>
            Display Name:
          </Label>
          <StyledInput
            type='text'
            onChange={handleDisplayName}
            value={displayName}
          />
        </InputContainer>

        <InputContainer>
          <Label>
            Description:
          </Label>
          <StyledTextarea
            onChange={handleDescripion}
            value={description}
          />
        </InputContainer>

        <InputContainer>
          <Label>
            Website:
          </Label>
          <StyledInput
            onChange={handleWebsite}
            value={website}
          />
        </InputContainer>

        <InputContainer>
          <Label>
            Phone:
          </Label>
          <StyledInput
            onChange={handlePhone}
            value={phone}
          />
        </InputContainer>

        <InputContainer>
          <Label>
            Address:
          </Label>
          <StyledTextarea
            onChange={handleAddress}
            value={address}
          />
        </InputContainer>

        <InputContainer>
          <Label>
            Ethereum Wallet Address:
          </Label>
          <StyledInput
            onChange={handleEthAddress}
            value={ethAddress}
          />
        </InputContainer>

        <InputContainer>
          <Label>
            Stream URL:
          </Label>
          <StyledInput
            onChange={handleStreamUrl}
            value={streamUrl}
          />
        </InputContainer>
      </DetailsColumn>
    </ColumnContainer>
  );
};

export default AdminVenueFields;
