import React, { useState } from 'react';
import styled from 'styled-components';
import DateTimePicker from 'react-datetime-picker';
import { useMoralis, useMoralisQuery } from 'react-moralis';
import Colors from '../../constants/Colors';
import Button from '../UI/Button';
import { Label } from '../UI/Text';
import Input, { Textarea, Select } from '../UI/Input';
import UploadPoster from './UploadPoster';
import Error from '../UI/Error';

const ErrorText = styled.p`
  border: 1px solid ${Colors.red};
  padding: 1em;
  margin: 1em 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PosterColumn = styled.div`
  width: 40%;
`;

const DetailsColumn = styled.div`
  width: 55%;
`;

const InputContainer = styled.div`
  margin-bottom: 2em;
`;

const InlineLabelText = styled.div`
  margin-left: 0.5em;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

// const StyledInlineInput = styled(Input)`
//   display: inline-block;
//   width: 5rem;
//   margin-right: 0.5em;
// `;

const StyledTextarea = styled(Textarea)`
  width: 100%;
`;

// const Suffix = styled.span`
//   font-size: 0.8em;
//   text-transform: none;
// `;

const DatePicker = styled.div`
  color: ${Colors.white};

  input, select {
    color: ${Colors.white};
  }
`;

const CreateShow = ({ closeModal, show }) => {
  const { Moralis } = useMoralis();
  const { data: venues, error: venuesError, isLoading } = useMoralisQuery('Venue', (query) => query
    .ascending('displayName'));

  const [name, setName] = useState(show?.attributes?.name || '');
  const [venueId, setVenueId] = useState(show?.attributes?.venue?.id || '');
  const [description, setDescription] = useState(show?.attributes?.description || '');
  const [slug, setSlug] = useState(show?.attributes?.slug || '');
  const [source, setSource] = useState(show?.attributes?.source || '');
  const [startDate, setStartDate] = useState(show?.attributes?.startDate || new Date());
  const [endDate, setEndDate] = useState(show?.attributes?.endDate || new Date());
  // const [doorsOpen, setDoorsOpen] = useState(show?.doors_open || null);
  // const [doorsClose, setDoorsClose] = useState(show?.doors_close || null);
  const [poster, setPoster] = useState(show?.attributes?.poster || null);
  const [featured, setFeatured] = useState(show?.attributes?.featured || false);
  const [errors, setErrors] = useState(false);
  const [apiError, setApiError] = useState('');
  const [userHasChangedSource, setUserHasChangedSource] = useState(false);

  const handleSetObject = async () => {
    if (name !== '' && slug !== '' && source !== '') {
      setErrors(false);

      const Show = Moralis.Object.extend('Show');
      const showObj = show || new Show();

      const Venue = Moralis.Object.extend('Venue');
      const venueObj = new Venue();
      venueObj.id = venueId;

      showObj.set('name', name);
      showObj.set('description', description);
      showObj.set('source', source);
      showObj.set('slug', slug);
      showObj.set('startDate', startDate);
      showObj.set('endDate', endDate);
      // showObj.set('doorsOpen', doorsOpen);
      // showObj.set('doorsClose', doorsClose);
      showObj.set('featured', featured);
      showObj.set('poster', poster);
      showObj.set('venue', venueObj);

      showObj.save()
        .then(() => {
          closeModal(); // close the modal
        }, (err) => {
          // Execute any logic that should take place if the save fails.
          // error is a Moralis.Error with an error code and message.
          setApiError(`Failed to create or update object, with error code: ${err.message}`);
        });
    } else {
      setErrors(true);
    }
  };

  const handleClose = () => {
    closeModal();
  };

  // const calcMinutesDiff = (date1, date2) => {
  //   const diffInMilliSeconds = Math.abs(new Date(date1) - new Date(date2)) / 1000;
  //   const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  //   return minutes;
  // };

  // const [openMins, setOpenMins] = useState(calcMinutesDiff(startDate, doorsOpen));
  // const [closeMins, setCloseMins] = useState(calcMinutesDiff(endDate, doorsClose));

  // const [openMins, setOpenMins] = useState(30);
  // const [closeMins, setCloseMins] = useState(15);

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleVenueSelect = (event) => {
    setVenueId(event.target.value);

    /** If this is a new show and they haven't already set a stream URL, grab the venue URL */
    if (!show && !userHasChangedSource) {
      if (event.target.value) {
        const venueIndex = venues.findIndex((item) => {
          if (item.id === event.target.value) { return true; }
          return false;
        });

        setSource(venueIndex >= 0 ? venues[venueIndex].attributes.streamUrl : '');
      } else {
        setSource('');
      }
    }
  };

  const handleDescripion = (event) => {
    setDescription(event.target.value);
  };

  const handleSlugInput = (event) => {
    setSlug(event.target.value);
  };

  const handleSourceInput = (event) => {
    setSource(event.target.value);
    setUserHasChangedSource(!!event.target.value);
  };

  const handleStartDate = (event) => {
    setStartDate(event);

    if (event > endDate) {
      console.log('updating end date');
      setEndDate(event);
    }
  };

  // const handleDoorsOpenInput = (event) => {
  //   // multiply minutes by 60000 is to convert minutes to milliseconds.
  //   console.log(startDate);
  //   const doorsOpenDate = new Date(startDate.getTime() - event.target.value * 60000);

  //   setOpenMins(event.target.value);
  //   setDoorsOpen(doorsOpenDate);
  // };

  // const handleDoorsCloseInput = (event) => {
  //   // multiply minutes by 60000 is to convert minutes to milliseconds.
  //   const doorsCloseDate = new Date(endDate.getTime() + event.target.value * 60000);

  //   setCloseMins(event.target.value);
  //   setDoorsClose(doorsCloseDate);
  // };

  if (venuesError) {
    return <Error label='Error' status={venuesError} />;
  }

  if ((!venues || venues.length === 0) && !isLoading) {
    return 'No venues found';
  }

  return (
    <div>
      <ColumnContainer>
        <PosterColumn>
          <UploadPoster poster={poster} setPoster={setPoster} />
        </PosterColumn>

        <DetailsColumn>
          <InputContainer>
            <Label>
              Event Name:
            </Label>
            <StyledInput
              type='text'
              placeholder='Lolas Halloween Special'
              onChange={handleName}
              value={name}
            />
          </InputContainer>

          <InputContainer>
            <Label>
              Description:
            </Label>
            <StyledTextarea
              placeholder='Join us on Halloween for a special stream featuring
              this band and that band, with special guest named this. Wear your
              costumes!'
              onChange={handleDescripion}
              value={description}
            />
          </InputContainer>

          <InputContainer>
            <Label>
              Venue:
            </Label>
            <Select onChange={handleVenueSelect} value={venueId}>
              <option value='none'>Choose a venue</option>
              {venues.map((item) => (
                <option key={item.id} value={item.id}>
                  (
                  {item.attributes.username}
                  )
                  {' '}
                  {item.attributes.displayName}
                </option>
              ))}
            </Select>
          </InputContainer>

          <InputContainer>
            <Label>
              URL Slug:
            </Label>
            <StyledInput
              type='text'
              placeholder='lolas-halloween-special'
              onChange={handleSlugInput}
              value={slug}
            />
          </InputContainer>

          <InputContainer>
            <Label>
              Stream Embed URL:
            </Label>
            <StyledInput
              type='text'
              placeholder='https://vimeo.com/event/12345/embed'
              onChange={handleSourceInput}
              value={source}
            />
          </InputContainer>

          <InputContainer>
            <Label>
              Start Date and Time:
            </Label>
            <DatePicker>
              <DateTimePicker
                onChange={handleStartDate}
                value={new Date(startDate)}
              />
            </DatePicker>
          </InputContainer>

          {/* <InputContainer>
            <Label>
              Doors Open (how many minutes before start time)
            </Label>
            <StyledInlineInput
              type='number'
              step='1'
              onChange={handleDoorsOpenInput}
              value={openMins}
            />
            <Suffix>mins</Suffix>
          </InputContainer> */}

          <InputContainer>
            <Label>
              End Date and Time:
            </Label>
            <DatePicker>
              <DateTimePicker
                onChange={setEndDate}
                value={new Date(endDate)}
                minDate={new Date(startDate)}
              />
            </DatePicker>
          </InputContainer>

          {/* <InputContainer>
            <Label>
              Doors Close (how many minutes after end time)
            </Label>
            <StyledInlineInput
              type='number'
              step='1'
              onChange={handleDoorsCloseInput}
              value={closeMins}
            />
            <Suffix>mins</Suffix>
          </InputContainer> */}

          <InputContainer>
            <Label>
              <Input
                type='checkbox'
                onChange={() => setFeatured(!featured)}
                defaultChecked={featured}
              />
              {' '}
              <InlineLabelText>Featured</InlineLabelText>
            </Label>
          </InputContainer>
        </DetailsColumn>
      </ColumnContainer>

      {errors && <ErrorText>Required fields are missing.</ErrorText>}
      {apiError && <ErrorText>{apiError}</ErrorText>}

      <ButtonContainer>
        <Button onClick={handleSetObject}>
          {show ? 'Update Show' : 'Create Show'}
        </Button>

        <Button type='secondary' onClick={handleClose}>
          Cancel
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default CreateShow;
