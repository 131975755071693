export default `
  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-Regular.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-Regular.woff') format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-Bold.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-Bold.woff') format('woff');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-Italic.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-Italic.woff') format('woff');
    font-style: italic;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-BoldItalic.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-BoldItalic.woff') format('woff');
    font-style: italic;
    font-weight: 700;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-Light.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-Light.woff') format('woff');
    font-wight: 300;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-LightItalic.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-LightItalic.woff') format('woff');
    font-wight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-SemiBold.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-SemiBold.woff') format('woff');
    font-wight: 600;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-SemiBoldItalic.woff') format('woff');
    font-wight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-Medium.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-Medium.woff') format('woff');
    font-wight: 500;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-MediumItalic.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-MediumItalic.woff') format('woff');
    font-wight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-ExtraBold.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-ExtraBold.woff') format('woff');
    font-weight: 800;
  }

  @font-face {
    font-family: 'Rebar';
    src: url('/fonts/rebar/Rebar-ExtraBold.woff2') format('woff2'),
        url('/fonts/rebar/Rebar-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: italic;
  }
`;
