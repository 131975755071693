import React, { useState } from 'react';
import styled from 'styled-components';
import { useMoralis, useWeb3Transfer } from 'react-moralis';
import NumericInput from 'react-numeric-input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useModal from '../../hooks/useModal';
import Colors from '../../constants/Colors';
import Button from '../UI/Button';
import { Label, P } from '../UI/Text';
import { H3 } from '../UI/Heading';
import Loader from '../UI/Loader';
import { ExternalLink } from '../UI/Link';

const StyledButton = styled(Button)`
  padding: 0.2em 0.5em;
`;

const StyledButtonNotConnected = styled(Button)`
  padding: 0.2em 0.5em;
  color: ${Colors.lightgrey};
  border-color: ${Colors.lightgrey};

  &:hover {
    color: ${Colors.lightestgrey};
    border-color: ${Colors.lightestgrey};
  }
`;

const ButtonContainer = styled.div`
  margin-top: 1em;
`;

const TipButton = ({ address, name }) => {
  const {
    Moralis, isAuthenticated, isWeb3Enabled, enableWeb3
  } = useMoralis();
  const [PaymentModal, openPaymentModal] = useModal({
    preventScroll: true
  });
  const [paymentAmount, setPaymentAmount] = useState(0.002365);

  const TransferEth = () => {
    const { fetch, isFetching } = useWeb3Transfer({
      amount: Moralis.Units.ETH(paymentAmount),
      receiver: address,
      type: 'native',
    });

    return (
      <ButtonContainer>
        <Button
          onClick={() => fetch({
            onSuccess: () => {
              toast.dark('Transfer successfully sent.', {
                position: 'top-center',
                autoClose: 5000,
                // hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
            },
            onError: (err) => {
              console.log(err);
              toast.error(err.message, {
                position: 'top-center',
                autoClose: 5000,
                // hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
            }
          })}
          disabled={isFetching}
        >
          {isFetching ? 'Waiting...' : 'Transfer'}
        </Button>
      </ButtonContainer>

    );
  };

  const handlePaymentModal = () => {
    enableWeb3();
    openPaymentModal();
  };

  if (!address || !isAuthenticated) {
    return null;
  }

  return (
    <>
      <StyledButton
        type='outline'
        onClick={() => handlePaymentModal()}
      >
        Tip This Venue
      </StyledButton>

      <PaymentModal>
        {!isWeb3Enabled
          ? (
            <>
              <Loader />
              <StyledButtonNotConnected
                type='outline'
                onClick={() => enableWeb3()}
              >
                Not connected to a wallet
              </StyledButtonNotConnected>
            </>
          )
          : (
            <>
              {name && <H3>{name}</H3>}

              <Label>Wallet address:</Label>
              <P>
                <ExternalLink href={`https://etherscan.io/address/${address}`}>
                  {address}
                </ExternalLink>
              </P>

              <Label>Tip amount (in ETH):</Label>
              <NumericInput
                step={0.001}
                precision={7}
                min={0}
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(e || 0)}
              />

              <TransferEth />
            </>
          )}

      </PaymentModal>

      <ToastContainer />
    </>
  );
};

export default TipButton;
