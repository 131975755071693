import React, { useRef } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import hexToRgba from 'hex-to-rgba';
import Colors from '../../constants/Colors';
import { H4 } from './Heading';
import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg';

/** https://www.cssportal.com/css-3d-transform-generator/ */
const Wrapper = styled.div`
  position: absolute;
  z-index: 100;
  ${(props) => props.perspective === 'right' && 'right: 2%;'}
  ${(props) => props.perspective === 'left' && 'left: 2%;'}
  ${(props) => props.perspective === 'middle' && 'left: 40%;'}
  top: 40%;
  perspective: 1000px;
  ${(props) => props.perspective === 'right' && 'perspective-origin: -120% 30%;'}
  ${(props) => props.perspective === 'left' && 'perspective-origin: 120% 30%;'}
  width: ${(props) => props.width};
`;

const Container = styled.div`
  position: relative;
  padding: 1em;
  background: ${hexToRgba(Colors.black, '0.8')};
  ${(props) => props.perspective === 'right' && 'transform: rotateY(-40deg);'}
  ${(props) => props.perspective === 'left' && 'transform: rotateY(40deg);'}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  width: 80%;
`;

const IconContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
`;

const DraggableModal = ({
  title,
  isOpen = false,
  close,
  children,
  width = 'auto',
  perspective = 'middle',
  elementId = 'root'
}) => {
  const nodeRef = useRef(null);

  if (isOpen === false) {
    return null;
  }

  return createPortal(
    <Draggable handle='.handle' nodeRef={nodeRef}>
      <Wrapper ref={nodeRef} width={width} perspective={perspective}>
        <Container>
          <Header>
            {title && <TitleContainer><H4 className='handle'>{title}</H4></TitleContainer>}
            <IconContainer>
              <CloseIcon onClick={() => close(true)} style={{ width: '1.2em', height: '1.2em' }} />
            </IconContainer>
          </Header>
          {children}
        </Container>
      </Wrapper>
    </Draggable>,
    document.getElementById(elementId)
  );
};

export default DraggableModal;
