import React, { createContext, useState } from 'react';

export const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [chatOpen, setChatOpen] = useState(true);
  const [channels, setChannels] = useState([]);
  const [currentChannel, setCurrentChannel] = useState(null);

  return (
    <ChatContext.Provider value={{
      chatOpen,
      setChatOpen,
      channels,
      setChannels,
      currentChannel,
      setCurrentChannel
    }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
