import { useState, useEffect } from 'react';

export default function useDevices() {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    // List cameras and microphones.
    // navigator.mediaDevices.enumerateDevices()
    //   .then((devices) => {
    //     devices.forEach((device) => {
    //       console.log(`${device.kind}: ${device.label
    //       } id = ${device.deviceId}`);
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(`${err.name}: ${err.message}`);
    //   });

    // Set all devices to state
    const getDevices = () => navigator.mediaDevices.enumerateDevices().then((returnedDevices) => {
      // console.log(devices.filter((device) => device.kind === 'videoinput').length > 0);
      setDevices(returnedDevices);
    });
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, []);

  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.filter((device) => device.kind === 'audioinput').length > 0,
    hasVideoInputDevices: devices.filter((device) => device.kind === 'videoinput').length > 0,
  };
}
