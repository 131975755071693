import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const StyledMain = styled.main`
  // height: calc(100vh - 18rem);
  // overflow: auto;
  width: 100%;
  max-width: ${(props) => (props.narrow ? '900px' : '1400px')};
  margin: 0 auto;
  padding: ${(props) => (props.toTop ? '0' : '18rem')} 0 1em;
  flex: 1;

  @media screen and (max-width: 600px) {
    padding: 10rem 0 1em;
  }
`;

const Main = ({
  narrow, toTop, className, children
}) => (
  <StyledMain narrow={narrow} toTop={toTop} className={className}>
    <Container>
      {children}
    </Container>
  </StyledMain>
);

export default Main;
