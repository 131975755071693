import React from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import Colors from '../../constants/Colors';
import { H2 } from './Heading';

const StyledBox = styled.div`
  ${(props) => !props.unstyled && `background: ${hexToRgba(Colors.black, '0.85')}`};
  ${(props) => !props.unstyled && `border: 1px solid ${Colors.grey}`};
  padding: 4rem;
  ${(props) => props.width && `width: ${props.width};`}
  max-width: 100%;
  max-height: 90vh;
  margin: 0 auto;
  overflow: auto;
  border-radius: 1em;
`;

const Header = styled(H2)`
  margin: -1rem -1rem 1rem;
  padding: 2rem;
  // border-bottom: 2px solid ${Colors.lightestgrey};
  transition: color 200ms ease-out;
`;

const Box = ({
  header, left = false, right = false, width, unstyled = false, className, children
}) => (
  <StyledBox
    className={className}
    left={left}
    right={right}
    unstyled={unstyled}
    width={width}
  >
    {header && <Header className='box-header'>{header}</Header>}
    {children}
  </StyledBox>
);

export default Box;
