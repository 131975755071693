import React, { useContext } from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { ShowContext } from '../../context/showContext';
// import { PartyContext } from '../../context/partyContext';
import Colors from '../../constants/Colors';
import { Strong } from '../UI/Text';

const Wrapper = styled.div``;

// const glow = keyframes`
//   from {
//     text-shadow:
//     0 0 4px ${hexToRgba(Colors.orange, '0.92')},
//     0 0 1px ${hexToRgba(Colors.orange, '0.34')},
//     // 0 0 10px ${hexToRgba(Colors.darkorange, '0.05')},
//     // 0 0 8px ${hexToRgba(Colors.darkorange, '0.10')},
//     0 0 5px ${hexToRgba(Colors.darkorange, '0.78')},
//     0 0 2px ${hexToRgba(Colors.darkorange, '0.92')};
//   }

//   to {
//     text-shadow:
//     0 0 3px ${hexToRgba(Colors.orange, '0.98')},
//     0 0 1px ${hexToRgba(Colors.orange, '0.42')},
//     // 0 0 8px ${hexToRgba(Colors.darkorange, '0.10')},
//     // 0 0 10px ${hexToRgba(Colors.darkorange, '0.05')},
//     0 0 5px ${hexToRgba(Colors.darkorange, '0.88')},
//     0 0 2px ${hexToRgba(Colors.darkorange, '1')};
//   }
// `;

const Stat = styled.div`
  color: ${Colors.lightorange};
  font-size: 0.8em;
  line-height: 1em;
  text-shadow:
    0 0 20px ${hexToRgba(Colors.lightorange, '0.4')},
    0 0 5px ${hexToRgba(Colors.darkorange, '0.8')};
`;

const ShowStats = ({ attendeeCount }) => {
  const {
    showDetails,
  } = useContext(ShowContext);
  // const { allParties } = useContext(PartyContext);

  return (
    <Wrapper>
      {showDetails && (
        <>
          <Stat>
            <Strong>
              Connected:
              {' '}
              {attendeeCount}
            </Strong>
            {/* <Strong>
              Parties:
              {' '}
              {allParties ? allParties.length : 0}
            </Strong> */}
          </Stat>
          <Stat />
        </>
      )}
    </Wrapper>
  );
};

export default ShowStats;
