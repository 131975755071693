import React, { useState } from 'react';
import styled from 'styled-components';
// import { useMoralis } from 'react-moralis';
import hexToRgba from 'hex-to-rgba';
import { ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useRole from '../../hooks/useRole';
// import useModal from '../../hooks/useModal';
import Container from '../UI/Container';
import Colors from '../../constants/Colors';
import NavigationItem from './Item';
// import UserCard from '../User/Card';
// import Button from '../UI/Button';
import Link, { ExternalLink } from '../UI/Link';
// import Avatar from '../User/Avatar';
import Logo from '../../assets/images/logo-glow.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/social-twitter.svg';

// const Placeholder = styled.div`
//   height: 6.3rem;
// `;

const NavBarContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledNav = styled(Container)`
  background: ${Colors.black};
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.5rem;
`;

const StyledUL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 2.5rem;

  @media screen and (max-width: 600px) {
    margin: 0;
  }
`;

const Hamburger = styled(NavigationItem)`
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

const DesktopNav = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const SidebarNav = styled.nav`
  position: fixed;
  top: 5rem;
  left: 0;
  right: 0;
  font-size: 2em;
  background: ${hexToRgba(Colors.black, '0.95')};
  border-bottom-right-radius: 1rem;
  padding: 1em 2em 1em 1.2em;
  z-index: 1000;
  display: ${(props) => (props.menuOpen ? 'block' : 'none')};
`;

const StyledMenuUL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledLI = styled.li`
  font-size: 0.9em;
  margin: 0 1rem;
  text-transform: uppercase;

  &.active {
    color: ${Colors.red};
  }
`;

const StyledLogo = styled.img`
  height: 3.5rem;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
`;

const StyledExternalLink = styled(ExternalLink)`
  display: block;
  text-decoration: none;
`;

const LogoLink = styled(Link)`
  display: inline-flex;
  margin-right: 2rem;
`;

// const StyledAvatar = styled(Avatar)`
//   width: 4.5rem;
//   height: 4.5rem;
//   margin-right: 1rem;
//   cursor: pointer;
// `;

// const StyledButton = styled(Button)`
//   margin-left: 1rem;
// `;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  cursor: cancel;
  display: ${(props) => (props.menuOpen ? 'block' : 'none')};
`;

// const UserContainer = styled.div`
//   display: flex;
//   align-items: center;
//   color: ${Colors.lightgrey};
//   cursor: pointer;
//   transition: color 200ms ease-in-out;

//   &:hover {
//     color: ${Colors.white};
//   }
// `;

// const Username = styled.div`
//   color: ${Colors.white};
//   font-size: 0.9em;
// `;

// const Address = styled.div`

// `;

const NavigationBar = () => {
  // const {
  //   isInitializing,
  //   authenticate,
  //   isAuthenticating,
  //   isAuthenticated,
  //   hasAuthError,
  //   authError,
  //   logout,
  //   user,
  // } = useMoralis();
  const isAdmin = useRole('Administrator');

  const [menuOpen, setMenuOpen] = useState(false);
  // const [profileOpen, setProfileOpen] = useState(false);
  // const [ProfileModal, openProfileModal, closeProfileModal] = useModal({
  //   preventScroll: true
  // });

  const handleHamburger = () => {
    setMenuOpen(!menuOpen);
  };

  // const handleProfile = () => {
  //   openProfileModal();
  //   setProfileOpen(!profileOpen);
  // };

  // useEffect(() => {
  //   if (hasAuthError) {
  //     toast.error(authError.message, {
  //       position: 'top-center',
  //       autoClose: 4000,
  //       closeOnClick: true,
  //       closeButton: false,
  //       draggable: false,
  //       progress: undefined,
  //     });
  //   }
  // }, [hasAuthError, authError]);

  // if (isInitializing) {
  //   return <Placeholder />;
  // }

  return (
    <>
      <Background menuOpen={menuOpen} onClick={() => setMenuOpen(false)} />
      <SidebarNav menuOpen={menuOpen}>
        <StyledMenuUL>
          <StyledLI>
            <StyledLink to='/'>Home</StyledLink>
          </StyledLI>
          <StyledLI>
            <StyledLink to='/about'>About Shomego</StyledLink>
          </StyledLI>
          {/* {isAuthenticated && isAdmin && (
          <>
            <StyledLI>
              <StyledLink to='/admin'>Admin</StyledLink>
            </StyledLI>
            <StyledLI>
              <StyledLink to='/show/practice'>Test Show</StyledLink>
            </StyledLI>
          </>
          )} */}
          {/* {isAuthenticated
            ? (
              <StyledLI>
                <ExternalLink onClick={() => logout()} disabled={isAuthenticating}>
                  Logout
                </ExternalLink>
              </StyledLI>
            )
            : (
              <StyledLI>
                <ExternalLink onClick={() => authenticate()}>Login</ExternalLink>
              </StyledLI>
            )} */}
        </StyledMenuUL>
      </SidebarNav>

      <NavBarContainer>
        <StyledNav>
          <StyledUL>
            <Hamburger onClick={handleHamburger} Icon={menuOpen ? CloseIcon : MenuIcon} title='Menu' />
            <LogoLink to='/'><StyledLogo src={Logo} alt='Shomego' /></LogoLink>
            <DesktopNav>
              <StyledLI>
                <StyledLink to='/about'>About</StyledLink>
              </StyledLI>
              {isAdmin && (
              <>
                <StyledLI>
                  <StyledLink to='/show/test'>Test Show</StyledLink>
                </StyledLI>
                <StyledLI>
                  <StyledLink to='/admin'>Admin</StyledLink>
                </StyledLI>
              </>
              )}
              <StyledLI>
                <StyledExternalLink href='https://twitter.com/Shomego_app'>
                  <div style={{ display: 'flex' }}>
                    <TwitterIcon style={{ width: '1.5em', height: '1.5em', marginRight: '0.3em' }} />
                    Follow
                  </div>
                </StyledExternalLink>
              </StyledLI>
            </DesktopNav>
          </StyledUL>
          <StyledUL>
            {/* {web3EnableError && ` ${web3EnableError.message}` } */}
            {/* {isAuthenticated ? (
              <>
                {!withoutDefault && (
                <UserContainer onClick={handleProfile}>
                  <StyledAvatar src={user.get('avatar')} />
                  <div>
                    <Username>
                      {user.get('displayName') || user.get('ethAddress').substring(0, 5)}
                    </Username>
                    <Address>
                      {user.get('ethAddress').replace(user.get('ethAddress')
                        .substring(5, 38), '***')}
                    </Address>
                  </div>
                </UserContainer>
                )}

                {children}

                <ProfileModal>
                  <UserCard closeModal={closeProfileModal} />
                </ProfileModal>
              </>
            ) : (
              <StyledButton onClick={() => authenticate()}>
                CONNECT
              </StyledButton>
            )} */}
          </StyledUL>
        </StyledNav>
      </NavBarContainer>

      <ToastContainer />
    </>
  );
};

export default NavigationBar;
