import React, { useState } from 'react';
import styled from 'styled-components';
import { useMoralis, useMoralisQuery } from 'react-moralis';
import Colors from '../../constants/Colors';
import Button from '../UI/Button';
import { H3 } from '../UI/Heading';
import { Strong, Italic } from '../UI/Text';
import Link from '../UI/Link';
import Loader from '../UI/Loader';
import Error from '../UI/Error';

const ActiveShow = styled.div`
  border: 1px solid ${Colors.grey};
  margin-top: -1px;
  position: relative;
  padding: 2em;
`;

const ShowDetails = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PosterContainer = styled.div`
  width: 9em;
  min-height: 100px;
  background: ${Colors.grey};
`;

const Poster = styled.img`
  width: 100%;
`;

const Details = styled.div`
  margin-left: 2em;
  width: calc(80% - 9em);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
`;

const StyledButton = styled(Button)`
  margin-right: 1em;
`;

const ListShows = ({ handleUpdate }) => {
  const [limit] = useState(12);
  const { Moralis } = useMoralis();
  const { data, error, isLoading } = useMoralisQuery('Show', (query) => query
    .limit(limit)
    .include('Venue', 'venueId')
    // .greaterThanOrEqualTo('endDate', new Date())
    .descending('startDate'),
  [limit],
  {
    live: true,
  });
  const isShowOver = (endDate) => new Date() < new Date(endDate);

  const VenueName = ({ venueId }) => {
    const [venueName, setVenueName] = useState('');

    if (!venueId) {
      return null;
    }

    const fetchVenue = async () => {
      const Venue = Moralis.Object.extend('Venue');
      const venue = new Venue();
      venue.id = venueId;

      await venue.fetch();
      setVenueName(venue.get('displayName'));
    };

    fetchVenue();

    return venueName;
  };

  const handleDelete = async (showObj) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this?')) {
      console.log(showObj);

      showObj.destroy().then(() => {
        // The object was deleted from the Moralis Cloud.
      }, (err) => {
        // The delete failed.
        // error is a Moralis.Error with an error code and message.
        console.error(err);
      });
      // if (token) {
      //   try {
      //     await deleteShow(id, token);
      //   } catch (e) {
      //     console.error(e);
      //   }
      // }
      // setLoading(false);
      // fetchShows();
    }
  };

  if (error) {
    return <Error label='Error' status={error} />;
  }

  if ((!data || data.length === 0) && !isLoading) {
    return 'No shows found';
  }

  return (
    isLoading ? <Loader />
      : (
        <div>
          {data.map((show) => (
            <ActiveShow key={show.id} ended={isShowOver(show.attributes.enddate)}>
              <ShowDetails>
                <PosterContainer>
                  {show.attributes.poster && <Poster src={show.attributes.poster} />}
                </PosterContainer>
                <Details>
                  <H3>
                    <Link to={`/show/${show.attributes.slug}`}>
                      {show.attributes.featured && '🌟 '}
                      {show.attributes.name}
                    </Link>
                  </H3>
                  <Strong>VENUE:</Strong>
                  {' '}
                  <VenueName venueId={show.attributes.venue?.id} />
                  <div>
                    <Strong>STARTS:</Strong>
                      &nbsp;
                    {new Date(show.attributes.startDate).toLocaleDateString(
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                    )}
                      &nbsp;at&nbsp;
                    {new Date(show.attributes.startDate).toLocaleTimeString(
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      }
                    )}
                  </div>
                  <div>
                    <Strong>ENDS:</Strong>
                      &nbsp;
                    {new Date(show.attributes.endDate).toLocaleDateString(
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                    )}
                      &nbsp;at&nbsp;
                    {new Date(show.attributes.endDate).toLocaleTimeString(
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      }
                    )}
                  </div>
                  {/* {show.description} */}
                  <div>
                    <Italic>
                      CREATED:&nbsp;
                      {new Date(show.createdAt).toLocaleDateString(
                        {
                          year: 'numeric',
                          month: 'full',
                          day: 'long'
                        }
                      )}
                    &nbsp;at&nbsp;
                      {new Date(show.createdAt).toLocaleTimeString(
                        {
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true
                        }
                      )}
                    </Italic>
                  </div>
                </Details>

                {error && error}

                <ButtonContainer>
                  <StyledButton type='secondary' onClick={() => handleUpdate(show)}>
                    Edit
                  </StyledButton>
                  <Button type='secondary' onClick={() => handleDelete(show)}>Delete</Button>
                </ButtonContainer>
              </ShowDetails>
            </ActiveShow>
          ))}
        </div>
      )
  );
};

export default ListShows;
