import React from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import Link, { FakeLink } from '../UI/Link';

const StyledLI = styled.li`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 6rem;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.7em;
  font-weight: 500;
  line-height: 1;
  width: 100%;
  margin: 0;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  user-select: none;
`;

const StyledFakeLink = styled(FakeLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.7em;
  font-weight: 500;
  line-height: 1;
  width: 100%;
  margin: 0;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  user-select: none;
`;

const StyledImage = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  // border: 2px solid transparent;
  // transition: border-color 100ms ease-in-out;

  // &:hover {
  //   border-color: ${Colors.pink};
  // }
`;

const NavigationItem = ({
  link, Icon, image, title, onClick, className
}) => (
  <StyledLI onClick={onClick} className={className}>
    {link ? (
      <StyledLink to={link}>
        {Icon && <Icon style={{ width: '3rem', height: '3rem' }} />}
        {image
        && (
        <StyledImage src={image} alt={title} />
        )}
        {/* {title} */}
      </StyledLink>
    )
      : (
        <StyledFakeLink>
          {Icon && <Icon style={{ width: '3rem', height: '3rem' }} />}
          {image
            && (
              <StyledImage src={image} alt={title} />
            )}
          {/* {title} */}
        </StyledFakeLink>
      )}
  </StyledLI>
);

export default NavigationItem;
