import React from 'react';
import Spinner from 'react-loader-spinner';
import Colors from '../../constants/Colors';
import styled from 'styled-components';

const StyledLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoaderText = styled.span`
  text-transform: uppercase;
  font-size: 1rem;
`;

const Loader = ({ text = 'Loading' }) => (
  <StyledLoader>
    <Spinner
      type='Bars'
      color={Colors.red}
      height={50}
      width={50}
      // timeout={3000}
    />
    <LoaderText>
      {text}
    </LoaderText>
  </StyledLoader>
);

export default Loader;
