import React, { useContext } from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import {
  Chat,
  MessageList,
  MessageInput,
  ChannelList,
  // MemberList,
  // TypingIndicator,
  // useChannelMembers,
  // usePresence,
  useUsers,
} from '@pubnub/react-chat-components';
import { ChatContext } from '../../context/chatContext';
import Colors from '../../constants/Colors';

const ChatContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 600px;
  background: ${hexToRgba(Colors.black, '0.8')};
  border-radius: 2rem;
  transition: all 0.3s ease-in-out;
  opacity: 0.8;

  &:hover {
    background: ${hexToRgba(Colors.black, '0.9')};
    opacity: 1;
  }
`;

const ChatHeader = styled.div`
  cursor: grab;
`;

function MyChat() {
  const {
    chatOpen, channels, currentChannel, setCurrentChannel
  } = useContext(ChatContext);
  const [users] = useUsers({ include: { customFields: true } });
  // const [totalPresence] = usePresence({
  //   channels: ['lolas-yucca-men-11062021'],
  // });
  // const [members] = useChannelMembers({
  //   channel: currentChannel,
  //   include: { customUUIDFields: true },
  // });
  // const [presentMembers, totalPresence] = usePresence({ channels: [currentChannel] });

  const handleSwitchChannel = (ch) => {
    setCurrentChannel(ch.id);
  };

  return (
    <ChatContainer open={chatOpen}>
      <Chat {...{ theme: 'dark', currentChannel, users }}>
        <ChatHeader className='chat-header'>
          <ChannelList
            channels={channels}
            onChannelSwitched={handleSwitchChannel}
          />
        </ChatHeader>
        <MessageList
          fetchMessages={100}
          // enableReactions
          // reactionsPicker={(
          //   <Picker
          //     title=''
          //     emoji=''
          //     showPreview={false}
          //     showSkinTones={false}
          //     theme='dark'
          //     emojiSize={22}
          //     perLine={6}
          //     sheetSize={16}
          //     exclude={['recent', 'nature', 'foods', 'activity', 'places', 'objects', 'symbols',
          // 'flags']}
          //   />
          // )}
        />
        <MessageInput
          placeholder=''
          senderInfo
          hideSendButton
          emojiPicker={(
            <Picker
              title=''
              emoji=''
              showPreview={false}
              showSkinTones={false}
              theme='dark'
              emojiSize={22}
              perLine={8}
              sheetSize={32}
            />
          )}
        />
        {/* <MemberList members={members} /> */}
        {/* <button
            onClick={() => setShowMembers(!showMembers)}
          >
            SHOW MEMBERS
          </button>
          <div className={`members ${showMembers && 'shown'}`}>
            <MemberList members={members} />
          </div>
          <div
            className={`people ${showMembers ? 'active' : ''}`}
            onClick={() => setShowMembers(!showMembers)}
          >
            <span>
              {totalPresence || 0}
              {' '}
              /
              {' '}
              {members.length || 0}
            </span>
          </div> */}
      </Chat>
    </ChatContainer>
  );
}

export default MyChat;
