import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { H1 } from '../components/UI/Heading';
import Main from '../components/UI/Main';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer';

const StyledIframe = styled.iframe`
  width: 100%;
  height: 60vh;
  border: 0;
`;

const Terms = () => (
  <>
    <Helmet>
      <title>
        Terms of Use - Shomego
      </title>
    </Helmet>
    <Navigation />
    <Main narrow>
      <H1>Terms of Use</H1>
      <StyledIframe src='https://app.termly.io/document/terms-of-use-for-website/2661713c-de7c-4ce7-bf09-b9134ee813b4' />
    </Main>
    <Footer />
  </>
);

export default Terms;
