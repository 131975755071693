import React, { createContext, useState, useCallback } from 'react';

export const VenueContext = createContext();

const VenueProvider = ({ children }) => {
  const [venuesList, setVenuesList] = useState([]);
  const [venueDetails, setVenueDetails] = useState(null);

  const createVenue = (data, token) => {
    console.log('Creating venue...');
    return fetch(`${process.env.REACT_APP_API}/venue/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.error) {
          console.log(res.error);
          return {
            error: res.error
          };
        }
        return res;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const updateVenue = (data, token) => {
    console.log('Updating venue...');
    return fetch(`${process.env.REACT_APP_API}/venue/update/${data.id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.error) {
          console.log(res.error);
          return {
            error: res.error
          };
        }
        return res;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const deleteVenue = (id, token) => {
    console.log('Deleting venue...');
    return fetch(`${process.env.REACT_APP_API}/venue/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res)
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const getVenues = useCallback((token) => {
    console.log('Listing venues...');
    return fetch(`${process.env.REACT_APP_API}/venue/list`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.error) {
          setVenuesList(res);
          return res;
        }
        return {
          error: res.error
        };
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getVenue = useCallback((username) => {
    console.log('Getting venue details...');
    return fetch(`${process.env.REACT_APP_API}/venue/details/${username}`)
      .then((res) => res.json())
      .then((res) => {
        if (!res.error) {
          setVenueDetails(res);
          return res;
        }
        return {
          error: res.error
        };
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <VenueContext.Provider value={{
      createVenue,
      updateVenue,
      deleteVenue,
      getVenues,
      getVenue,
      venuesList,
      venueDetails
    }}
    >
      {children}
    </VenueContext.Provider>
  );
};

export default VenueProvider;
