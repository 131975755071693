import React, { createContext, useState } from 'react';

export const PartyContext = createContext();

const PartyProvider = ({ children }) => {
  const [party, setParty] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [allParties, setAllParties] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [invalidPermissions, setInvalidPermissions] = useState(false);
  const [room, setRoom] = useState(null);
  const [localTracks, setLocalTracks] = useState([]);
  const [videoOn, setVideoOn] = useState(false);
  const [audioOn, setAudioOn] = useState(false);

  // To create or join a party
  const setupParty = async (showId, partyId, uuid) => {
    setParty(null);
    /**
     * A Twilio room name is based on the UUID of the user who created it. It's possible that a user
     * could have mulitple rooms (in different shows) at the same time, so we prepend the showID to
     * their UUID for a completely unique room name.
     * */
    let roomId;
    /**
     * partyId will be null if we are creating a new party. In that case, we need to just use
     * the UUID of the user creating the party. If partyId is not null, then that means we are
     * joining an existing party, and the name is already in the format of showId.UUID where UUID
     * is of the creator of that party.
     */
    if (partyId) {
      roomId = partyId;
    } else {
      roomId = `${showId}.${uuid}`;
    }

    await fetch(`${process.env.REACT_APP_API}/token?identity=${uuid}&room=${roomId}`, {
      method: 'GET',
    })
      .then((res) => res.text())
      .then((res) => {
        setParty({
          token: res,
          id: roomId
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        setParty(null);
      });
  };

  // To disconnect from a party
  const leaveParty = () => {
    setParty(null);
  };

  return (
    <PartyContext.Provider value={{
      party,
      setParty,
      participants,
      setParticipants,
      room,
      setRoom,
      localTracks,
      setLocalTracks,
      allParties,
      setAllParties,
      isConnecting,
      setIsConnecting,
      invalidPermissions,
      setInvalidPermissions,
      videoOn,
      setVideoOn,
      audioOn,
      setAudioOn,
      setupParty,
      leaveParty,
    }}
    >
      {children}
    </PartyContext.Provider>
  );
};

export default PartyProvider;
