import React, {
  useContext, useEffect
} from 'react';
import styled from 'styled-components';
/**
 * Please read the recommendations and best practices for using webRTC
 * https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices
 */
import TwilioVideo from 'twilio-video';
import { useMoralis } from 'react-moralis';
import useLocalTracks from '../../hooks/useLocalTracks';
import { useBeforeunload } from 'react-beforeunload';
import { PartyContext } from '../../context/partyContext';
import { ShowContext } from '../../context/showContext';
import { SocketContext } from '../../context/socketContext';
import { ChatContext } from '../../context/chatContext';
import Colors from '../../constants/Colors';
import ParticipantList from './ParticipantList';
// import AudioLevel from './AudioLevel';

const StatusContainer = styled.div`
  width: 100%;
  margin: 0 1% 1%;
  font-size: 0.8em;
`;

const Label = styled.div`
  display: inline-block;
  color: ${Colors.green};
  line-height: 1;
`;

const Party = () => {
  const {
    party,
    setParty,
    setParticipants,
    room,
    setRoom,
    isConnecting,
    setIsConnecting,
    invalidPermissions,
    setInvalidPermissions,
  } = useContext(PartyContext);
  const { localTracks } = useLocalTracks();
  const { channels, setChannels, setCurrentChannel } = useContext(ChatContext);
  const { showDetails } = useContext(ShowContext);
  const { socket } = useContext(SocketContext);
  const { user, Moralis } = useMoralis();

  const addParticipant = async (participant) => {
    if (participant) {
      console.log(`Getting user meta info for ${participant.identity}`);
      const foundUser = await Moralis.Cloud.run('getUser', { userId: participant.identity });

      let meta = {};

      if (foundUser) {
        meta = {
          username: foundUser.displayName || foundUser.ethAddress.substring(0, 5),
          avatar: foundUser.avatar
        };
      } else {
        meta = {
          username: 'Unknown',
          avatar: null
        };
      }
      const newParticipant = Object.assign(participant, meta);
      setParticipants((prevParticipants) => [...prevParticipants, newParticipant]);
    }
  };

  useEffect(() => {
    /**
     * We need a variable outside of the fetch return function to be able to call this party when
     * we disconnect from it
     * NOTE: For simplicity, we are calling parties "rooms" here because that is Twilio's
     * terminology for the video rooms.
     */
    let connectedRoom = null;
    let partyName = '';
    /** Reset permissions check to see if anything has changed */
    setInvalidPermissions(false);

    if (!connectedRoom && party) {
      setIsConnecting(true);

      // Display the audio level.
      // AudioLevel(audioTrack, (level) => {
      //   /* Update audio level indicator. */
      //   console.log('audio level:');
      //   console.log(level);
      // });

      console.log(localTracks);

      TwilioVideo.connect(party.token, {
      /**
         * ConnectOptions ensure the best setup for the call. These will probably
         * need modifying as we work through scaling
         * https://media.twiliocdn.com/sdk/js/video/releases/2.3.0/docs/global.html#ConnectOptions
         */
        name: party.id,
        // tracks,
        // tracks: [],
        tracks: localTracks,
        // audio: true,
        // video: { width: 200 },
        // logLevel: 'debug'
      }).then((newRoom) => {
        // console.log('TWILIO: Connected to room "%s"', newRoom.name);
        /** For access to the room object outside of this return function */
        connectedRoom = newRoom;

        /** For accessing this room for local track events, like disabling */
        setRoom(newRoom);

        setInvalidPermissions(false);

        setIsConnecting(false);

        socket.emit('joinParty', {
          showId: showDetails.id,
          partyId: party.id,
          userId: user.id,
          username: user.get('displayName') || user.get('ethAddress').substring(1, 5),
          avatar: user.get('avatar')
        });

        socket.on('joinedParty', (message) => {
          // console.log(message);
          partyName = message;
          // toast.dark(message, {
          //   position: 'bottom-center',
          //   autoClose: 2000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   closeButton: false,
          //   pauseOnHover: false,
          //   draggable: true,
          //   progress: undefined,
          // });

          /** Update your channels list to include this party */
          setChannels([
            ...channels,
            {
              name: 'Party',
              id: partyName
            }
          ]);

          /** Set the currentChannel for chat to be for this party */
          setCurrentChannel(partyName);
        });

        socket.on('log', (log) => {
          console.log(log);
          setIsConnecting(false);
        });

        /** By default, we want people to join parties without video or mic enabled */
        // newRoom.localParticipant.videoTracks.forEach((track) => {
        //   if (track) {
        //     track.track.disable();
        //   }
        // });

        // newRoom.localParticipant.audioTracks.forEach((track) => {
        //   if (track) {
        //     track.track.disable();
        //   }
        // });

        const participantConnected = (participant) => {
          console.log('Participant "%s" connected', participant.identity);
          addParticipant(participant);
          // console.log(returnedMeta);
          // const newParticipant = {
          //   ...returnedMeta,
          //   ...participant
          // };
          // console.log(newParticipant);
          // setParticipants((prevParticipants) => [...prevParticipants, participant]);

          // participant.on('trackSubscribed', (track) => {
          //   console.log('participant trackSubscribed!');
          // });
        };

        const participantDisconnected = (participant) => {
          console.log('Participant "%s" disconnected', participant.identity);
          setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
          // setParticipantsMeta(
          //   (prevMeta) => prevMeta.filter((p) => p.identity !== participant.identity)
          // );
        };

        newRoom.participants.forEach(participantConnected);
        newRoom.on('participantConnected', participantConnected);
        newRoom.on('participantDisconnected', participantDisconnected);
        newRoom.once('disconnected', (error) => {
          if (error) { console.log(error); }
          newRoom.participants.forEach(participantDisconnected);
        });

        /**
         * When the user closes the tab/browser or navigates to another web page, we
         * recommend that you disconnect from the Room so that other Participants are
         * immediately notified.
         */
        // Listen to the "beforeunload" event on window to leave the Room
        // when the tab/browser is being closed.
        window.addEventListener('beforeunload', () => newRoom && newRoom.disconnect());

        // iOS Safari does not emit the "beforeunload" event on window.
        // Use "pagehide" instead.
        window.addEventListener('pagehide', () => newRoom && newRoom.disconnect());
      }).catch((err) => {
        console.log('Failed to join twilio room');
        setParty(null);
        setIsConnecting(false);
        setInvalidPermissions(true);
        console.log(err);
      });
    }

    return () => {
      if (connectedRoom) {
        console.log(`Leaving party: ${partyName}`);
        // removeLocalVideoTrack();
        connectedRoom.disconnect();

        socket.emit('leaveParty', {
          showId: showDetails.id,
          partyId: party.id,
          userId: user.id,
          username: user.get('username')
        });

        /** Update your channels list to remove this party */
        setChannels([
          {
            name: 'Public',
            id: showDetails.id
          }
        ]);

        /** Set the currentChannel for chat to be for the show */
        setCurrentChannel(showDetails.id);

        setParty(null);
        connectedRoom = null;
      }
    };
  }, // eslint-disable-next-line
  [
    party,
    showDetails,
    socket,
    setInvalidPermissions,
    setIsConnecting,
    setParty,
    setParticipants,
    // addParticipant,
    user,
  ]);

  useBeforeunload(() => {
    socket.emit('leaveParty', {
      showId: showDetails.id,
      partyId: party.id,
      userId: user.id,
      username: user.get('username')
    });
  });

  if (isConnecting) {
    return (
      <StatusContainer><Label>Connecting...</Label></StatusContainer>
    );
  }

  return (
    !invalidPermissions && room ? <ParticipantList /> : null
  );
};

export default Party;
