import React from 'react';
import { Link } from 'react-router-dom';
import { Colors, Layout } from '../../constants';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${Colors.white};
  margin: 0;
  padding: 0;
  // text-decoration: none;
  transition: all ${Layout.transition};

  &:hover {
    color: ${Colors.purple};
  }
`;

const StyledExternalLink = styled.a`
  color: ${Colors.white};
  // text-decoration: none;
  transition: all ${Layout.transition};
  cursor: pointer;

  &:hover {
    color: ${Colors.purple};
  }
`;

export const FakeLink = styled.a`
  color: ${Colors.white};
  margin: 0;
  padding: 0;
  // text-decoration: none;
  transition: all ${Layout.transition};

  &:hover {
    color: ${Colors.purple};
  }
`;

export const ExternalLink = ({
  children, href, className, onClick
}) => (
  <StyledExternalLink
    href={href}
    className={className}
    target='_blank'
    rel='noreferrer'
    onClick={onClick}
  >
    {children}
  </StyledExternalLink>
);

const OurLink = ({ children, to, className }) => (
  <StyledLink to={to || '/333'} className={className}>
    {children}
  </StyledLink>
);

export default OurLink;
