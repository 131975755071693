import {
  useCallback, useEffect, useState, useContext
} from 'react';
import { useMoralis } from 'react-moralis';
import PubNub from 'pubnub';
import { ChatContext } from '../context/chatContext';

const usePubnub = (showSlug, setAttendeeCount) => {
  const { user, isUserUpdating, isAuthenticated } = useMoralis();
  const [pubnub, setPubnub] = useState(null);
  const { setChannels, setCurrentChannel } = useContext(ChatContext);

  /** Initialize PubNub service to keep track of show attendees only */
  useEffect(() => {
    if (user && isAuthenticated) {
      // console.log('initializing chat');
      const initalizedPubnub = new PubNub({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE,
        uuid: user.get('ethAddress').substring(0, 5)
      });

      setPubnub(initalizedPubnub);
    }
  }, [user, isAuthenticated]);

  const updateUsermeta = useCallback(async () => {
    console.log('updating chat meta');
    // console.log({
    //   name: user.get('displayName') || user.get('ethAddress').substring(0, 5),
    //   profileUrl: user.get('avatar') || null
    // });
    try {
      const result = await pubnub.objects.setUUIDMetadata({
        data: {
          name: user.get('displayName') || user.get('ethAddress').substring(0, 5),
          profileUrl: user.get('avatar') || null
        },
      });
      console.log(result);
    } catch (status) {
      console.log('operation failed w/ error:', status);
    }
  }, [pubnub, user]);

  useEffect(() => {
    if (pubnub && isAuthenticated && !isUserUpdating) {
      updateUsermeta();
    }
  }, [isUserUpdating, pubnub, isAuthenticated, updateUsermeta]);

  const handlePresence = useCallback((event) => {
    const channelName = event.channel;
    const { occupancy } = event;

    if (channelName === showSlug) {
      setAttendeeCount(occupancy);
    }
  }, [showSlug, setAttendeeCount]);

  const subscribe = useCallback(() => {
    if (user && pubnub && showSlug && isAuthenticated) {
      // console.log('adding listener');
      pubnub.addListener({
        presence: handlePresence
      });

      pubnub.subscribe({
        channels: [showSlug],
        withPresence: true
      });

      pubnub.hereNow(
        {
          channels: [showSlug],
          includeState: true
        },
        (status, response) => {
          if (response) {
            setAttendeeCount(response.totalOccupancy);
          }
        }
      );

      pubnub.objects.setUUIDMetadata({
        data: {
          name: user.get('displayName') || user.get('ethAddress').substring(0, 5),
          profileUrl: user.get('avatar') || null
        }
      });

      /** Update your channels list to include this show */
      setChannels([
        {
          name: 'Public',
          id: showSlug
        }
      ]);

      /** Set the currentChannel for chat to be for this show */
      setCurrentChannel(showSlug);
    }
  }, [
    user,
    pubnub,
    showSlug,
    isAuthenticated,
    handlePresence,
    setAttendeeCount,
    setChannels,
    setCurrentChannel
  ]);

  return { pubnub, subscribe };
};

export default usePubnub;
