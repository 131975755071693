import React from 'react';
// import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import Chat from './Chat';

function ChatProvider({ pubnub }) {
  /** Initialize PubNub service */
  // const initalizedPubnub = new PubNub({
  //   publishKey: process.env.REACT_APP_PUBNUB_PUBLISH,
  //   subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE,
  //   uuid,
  // });

  // initalizedPubnub.objects.setUUIDMetadata({
  //   data: {
  //     profileUrl: avatar
  //   }
  // });

  return (
    <PubNubProvider client={pubnub}>
      <Chat />
    </PubNubProvider>
  );
}

export default ChatProvider;
