import React from 'react';
import Loader from '../components/UI/Loader';
import Header from '../components/Header';
import Navigation from '../components/Navigation/Navigation';

const Splash = () => (
  <>
    <Header />
    <Loader />
    <Navigation />
  </>
);

export default Splash;
