import React from 'react';
import styled from 'styled-components';
import { H1 } from './Heading';
import { P } from './Text';

const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 0 4em;

  @media screen and (max-width: 600px) {
    padding: 2em 0;
    flex-direction: column;
  }
`;

const HeroLeft = styled.div`
  width: 45%;

  @media screen and (max-width: 900px) {
    width: 48%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const HeroRight = styled.div`
  width: 45%;

  @media screen and (max-width: 900px) {
    width: 48%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Intro = styled(P)`
  font-size: 1.2em;
`;

const Hero = ({ headline, intro, children }) => (
  <HeroContainer>
    <HeroLeft>
      {headline && <H1>{headline}</H1>}
      {intro && <Intro>{intro}</Intro>}
    </HeroLeft>
    <HeroRight>
      {children}
    </HeroRight>
  </HeroContainer>
);

export default Hero;
