import React, { useCallback, useState } from 'react';
import disableScroll from 'disable-scroll';
import Modal from '../components/UI/Modal';
// import Modal from '../components/DraggableModal';

/**
 * See https://github.com/shibe97/react-hooks-use-modal for reference
 * I decided to not append it to the root, so I removed elementIds
 */
const useModal = (options = {}) => {
  const { preventScroll = false, allowBackgroundClose = true } = options;
  const [isOpen, setOpen] = useState(false);

  const open = useCallback(() => {
    setOpen(true);
    if (preventScroll) {
      disableScroll.on();
    }
  }, [setOpen, preventScroll]);

  const close = useCallback(() => {
    setOpen(false);
    if (preventScroll) {
      disableScroll.off();
    }
  }, [setOpen, preventScroll]);

  const ModalWrapper = useCallback(({ children }) => (
    <Modal isOpen={isOpen} close={close} allowBackgroundClose={allowBackgroundClose}>
      {children}
    </Modal>
  ), [isOpen, close, allowBackgroundClose]);

  return [ModalWrapper, open, close, isOpen];
};

export default useModal;
