import { useCallback, useContext, useRef } from 'react';
import { useMoralis } from 'react-moralis';
import { PartyContext } from '../context/partyContext';
import { SocketContext } from '../context/socketContext';

/**
 * Returns nothing. Just disconnects a user from all parties and shows they are in.
 *
 * @param {object} socket A reference to the currently opened socket
 */
const useDisconnect = (showSlug) => {
  const { user } = useMoralis();
  const { party } = useContext(PartyContext);
  const partyRef = useRef(0);
  partyRef.current = party;
  const { socket } = useContext(SocketContext);

  const disconnect = useCallback(() => {
    console.log('Disconnecting from any party sockets...');
    if (!showSlug || !socket) return false;

    /**
     * Tell the socket we are leaving any parties this user is in before we disconnect
     * from the socket. If we rely on the Party.js component to emit the request to leaveParty
     * before it unmounts, it will fail because this return will fire first and disconnect() the
     * socket before the Party component has a chance to emit it's request.
     */
    if (partyRef.current) {
      socket.emit('leaveParty', {
        show: showSlug, party: partyRef.current.name, username: user.get('username')
      });
    }

    return true;
  }, [socket, showSlug, user]);

  return { disconnect };
};

export default useDisconnect;
