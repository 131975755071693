import React from 'react';
import styled from 'styled-components';
import { useMoralisQuery } from 'react-moralis';
// import Colors from '../../constants/Colors';
// import { Strong } from '../UI/Text';
import { ExternalLink } from '../UI/Link';
import { Label } from '../UI/Text';
import TipButton from '../Tip/TipButton';
import { Colors } from '../../constants';

const Wrapper = styled.div`
  display: flex;
`;

const StyledLabel = styled(Label)`
  margin-right: 1em;
`;

const StyledLink = styled(ExternalLink)`
  text-decoration: none;
  color: ${Colors.lightestgrey};
`;

const VenueUsername = styled.span`
  font-size: 0.9em;
  color: ${Colors.orange};
  background: ${Colors.grey};
  padding: 0.2em 0.5em;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
`;

const VenueInfo = ({ venueId }) => {
  const { data, error, isLoading } = useMoralisQuery('Venue', (query) => query
    .equalTo('objectId', venueId)
    .limit(1));

  if (error) {
    return (<div>Unable to load venue information</div>);
  }

  if (data?.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <StyledLabel>
        <StyledLink href={`/@${data[0]?.attributes?.username}`}>
          <VenueUsername>
            @
            {data[0]?.attributes?.username}
          </VenueUsername>
          {data[0]?.attributes?.displayName}
        </StyledLink>
      </StyledLabel>
      {isLoading
        ? <div>Loading button...</div>
        : (
          <TipButton
            address={data[0]?.attributes?.ethAddress}
            name={data[0]?.attributes?.displayName}
          />
        ) }
    </Wrapper>
  );
};

export default VenueInfo;
