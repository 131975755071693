import styled from 'styled-components';
import Colors from '../../constants/Colors';

export const P = styled.p`
  margin: 0 0 1em;
`;

export const Strong = styled.strong`
  font-weight: 600;
`;

export const Italic = styled.em`
  font-style: italic;
`;

export const LI = styled.li`
  margin: 0 0 1em;
`;

export const UL = styled.ul`
  margin: 1.5em 0;
  padding: 0 0 0 3em;
`;

export const Span = styled.span``;

export const Nameplate = styled.div`
  padding: 0.5em;
  text-align: center;
  font-size: 1em;
  line-height: 1;
`;

export const Label = styled.label`
  text-transform: uppercase;
  font-size: 0.9em;
  text-align: left;
  display: flex;
  align-items: center;
  font-family: 'Rebar', 'IBM Plex Mono', sans-serif;
  color: ${Colors.lightgrey};
`;

export const Subtext = styled.p`
  font-style: italic;
  font-size: 0.8em;
  line-height: 1.2;
`;

export const Plate = styled.div`
  display: inline-block;
  padding: 0.5em;
  background: ${Colors.white};
  text-transform: uppercase;
  color: ${Colors.black};
  font-size: 1em;
  line-height: 1;
  font-weight: 600;
`;

export const PlateDark = styled.div`
  display: inline-block;
  padding: 0.5em;
  background: ${Colors.black};
  text-transform: uppercase;
  color: ${Colors.white};
  font-size: 1em;
  line-height: 1;
  // font-weight: 400;
`;
