import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import Fonts from './Fonts';
import { Colors, Layout } from '.';
import BackgroundImage from '../assets/images/abstract.svg';
import DefaultAvatar from '../assets/images/avatar-default.png';

export default createGlobalStyle`
  ${normalize()}
  ${Fonts}

  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  #root {
    position: relative;
  }

  html {
    font-size: 62.5%;

    @media screen and (max-width: 600px) {
      font-size: 50%;
    }

    @media screen and (max-height: 600px) {
      font-size: 50%;
    }
  }

  body {
    position: relative;
    background: ${Colors.black} url('${BackgroundImage}') no-repeat;
    background-size: cover;
    color: ${Colors.white};
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.6;
    // overflow: hidden;
    // overscroll-behavior-y: none;
  }

  #app {
    display: flex;
    flex-direction: column;
  }

  a {
    color: ${Colors.orange};
  }

  body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  body::-webkit-scrollbar-thumb {
    background: ${Colors.lightgrey};
    transition: background ${Layout.transition};
  }

  body::-webkit-scrollbar-thumb:hover {
    background: ${Colors.pink};
  }

  body::-webkit-scrollbar-track {
    background: linear-gradient(90deg,#201c29,#201c29 1px,#100e17 0,#100e17);
  }

  // main {
  //   flex: 1 1 0;
  // }

  // Input range styling, see: http://danielstern.ca/range.css/#/
  .rangeslider-vertical {
    background: ${Colors.grey};
    max-width: 0.5rem;
    border-radius: 10px;
    margin: 0 auto;
    height: 85%;
  }

  .rangeslider-horizontal {
    background: ${Colors.grey};
    max-height: 0.5rem;
    border-radius: 10px;
    margin: 0 auto;
    width: 85%;
  }

  .rangeslider-vertical .rangeslider__fill,
  .rangeslider-horizontal .rangeslider__fill {
    background: ${Colors.purple};
  }

  .rangeslider-vertical .rangeslider__handle,
  .rangeslider-horizontal .rangeslider__handle {
    width: 13px;
    height: 13px;
    background: ${Colors.pink};
    border: 0;
    border-radius: 19px;
    -webkit-appearance: none;
    left: -4px;
  }

  .rangeslider-horizontal .rangeslider__handle::after {
    display: none;
  }

  .rangeslider-vertical .rangeslider__handle-tooltip {
    transform: translate3d(-100%, -50%, 0);
  }

  .rangeslider-horizontal .rangeslider__handle-tooltip {
    user-select: none;
  }

  input[type=range] {
    width: 100%;
    margin: 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    background: ${Colors.grey};
    border: 0;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    // cursor: pointer;
  }
  input[type=range]::-webkit-slider-thumb {
    // margin-top: -10px;
    width: 10px;
    height: 10px;
    background: ${Colors.pink};
    border: 0;
    border-radius: 19px;
    // cursor: pointer;
    -webkit-appearance: none;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: ${Colors.purple};
  }
  input[type=range]::-moz-range-track {
    background: ${Colors.grey};
    border: 0;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    // cursor: pointer;
  }
  input[type=range]::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: ${Colors.pink};
    border: 0;
    border-radius: 19px;
    // cursor: pointer;
  }
  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 0 0;
    color: transparent;
    width: 100%;
    height: 10px;
    // cursor: pointer;
  }
  input[type=range]::-ms-fill-lower {
    background: ${Colors.deepblue}
    border: 0;
  }
  input[type=range]::-ms-fill-upper {
    background: ${Colors.grey};
    border: 0;
  }
  input[type=range]::-ms-thumb {
    width: 10px;
    height: 10px;
    background: ${Colors.pink};
    border: 0;
    border-radius: 19px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type=range]:focus::-ms-fill-lower {
    background: ${Colors.deepblue};
  }
  input[type=range]:focus::-ms-fill-upper {
    background: ${Colors.deepblue};
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from https://stackoverflow.com/a/32202953/7077589 */
    input[type=range] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }

  .swiper-container {
    padding-bottom: 5rem;
    width: 100%;
    max-width: 100%;
    // max-height: 90vh;

    @media screen and (max-width: 600px) {
      padding: 0 0 5rem;
    }
  }

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    width: 4rem;
    height: 0.5rem;
    background: ${Colors.lightgrey};
    border-radius: 2px;
  }

  .swiper-pagination-bullet-active {
    background: ${Colors.lightestgrey};
  }

  .tooltip-theme {
    background: black !important;
    padding: 1rem !important;
    border: 1px solid white !important;
    margin-top: 0 !important;
  }

  .tooltip-theme.show {
    opacity: 1 !important;
  }

  .tooltip-theme::before,
  .tooltip-theme::after {
    display: none !important;
  }

  // PubNub Chat overrides
  :root {
    // --msg__alignItems: center;
    --msg__padding: 0 1rem;
    --msg-list__padding: 0;
    --msg__avatar__display: block;
    --msg__avatar__margin: 0 1rem 0 0;
    --msg__avatar__size: 5rem;
    --msg__avatar__borderRadius: 0.8rem;
    --msg__author__fontSize: 11px;
    --msg__author__fontWeight: 600;
    --msg__time__fontSize: 10px;
    --msg-input__padding: 0;
    --msg-input__textarea__padding: 0.5rem 1rem 0;
    --msg-input__textarea__fontSize: 12px;
    --msg-input__textarea__lineHeight: 16px;
    --msg-input__textarea__borderRadius: 0;
    --msg-list__unread__color: ${Colors.orange};
    --msg-list__unread__background: ${Colors.darkgrey};
    --msg-input__send__margin: 5px;
    --msg-input__send__borderRadius: 0;
    --msg-input__textarea__borderRadius: 1rem;
    --msg-input__send__padding: 5px 8px;
    --msg__bubble__boxShadow: none;
    --msg__bubble__borderRadius: 0.8rem;
    --msg__bubble__padding: 0.5rem 1rem;
    --msg__bubble__margin: 0;
    --msg__bubble__fontSize: 0.8em;
    --msg__title__margin: 0;
    --msg__main__maxWidth: 85%;
    --msg-input__icon__margin: 0 10px;
    --channel__name__fontSize: 11px;
    --channel__padding: 8px 12px;
  }

  .pn-msg-list {
    --msg-list__background: transparent;
    --msg--hover__background: transparent;
    --msg__bubble__background: ${Colors.grey};
    --msg__bubble__color: ${Colors.white};
    --msg__author__color: ${Colors.lightgrey};
    border: 1px solid ${Colors.grey};
    height: calc(100% - 70px);
  }

  .pn-msg {
    margin-bottom: 0.6em;
  }

  .pn-msg__content {
    width: 100%;
  }

  .pn-msg__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .pn-msg__time {
    // display: none;
    color: ${Colors.lightgrey};
  }

  .pn-msg__avatar-placeholder {
    background: ${Colors.darkgrey} url('${DefaultAvatar}') no-repeat;
    background-size: cover;
  }

  .pn-msg__avatar {
    width: var(--msg__avatar__size);
    height: var(--msg__avatar__size);
    background: ${Colors.darkgrey};
    border-radius: 0.8rem;
    // border: 2px solid ${Colors.grey};

    img {
      height: 100%;
      // Hide alt text on missing avatar images
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .pn-msg__main {
    width: calc(100% - 6rem);
    word-break: break-word;
  }

  .pn-msg-input {
    --msg-input__background: transparent;
    --msg-input__textarea__background: transparent;
    --msg-input__send__background: ${Colors.black};
    --msg-input__send__color: ${Colors.white};
    --msg-input__textarea__color: ${Colors.white};
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .pn-msg__bubble {
    margin-top: 0.2rem;
    text-transform: none;
  }

  .pn-channel-list {
    display: flex;
    height: auto;
    --channel-list__background: ${Colors.black};
    border: 1px solid ${Colors.grey};
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .pn-channel {
    border-right: 2px solid ${Colors.grey};
  }

  .pn-channel__name {
    color: ${Colors.lightgrey};
  }

  .pn-channel--active .pn-channel__name {
    color: ${Colors.white};
  }

  .pn-channel--active {
    background: transparent;
  }

  .pn-msg-list__unread {
    position: absolute;
    transform: translateX(-50%);
    top: 10px;
    --msg-list__unread__offset: 0;
    --msg-list__unread__padding: 5px 10px;
    text-align: center;
  }

  .pn-msg-list-scroller {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${Colors.grey};
      transition: background ${Layout.transition};
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${Colors.pink};
    }

    &::-webkit-scrollbar-track {
      background: linear-gradient(90deg,#201c29,#201c29 1px,#100e17 0,#100e17);
    }
  }

  .contentWrapper {
    font-size: 0.8em;
  }

  .styled-toggle.react-toggle--checked .react-toggle-track {
    background-color: ${Colors.green};
  }

  .styled-toggle.react-toggle--disabled .react-toggle-track {
    background-color: ${Colors.pink};
  }

  .custom-resize-handle {
    width: 0.5rem;
    height: 8rem;
    background: ${Colors.grey};
    border-radius: 4px;

    @media screen and (max-width: 800px) and (min-height: 500px) {
      display: none;
    }
  }

  .custom-resize-handle-vertical {
    width: 8rem;
    height: 0.5rem;
    background: ${Colors.grey};
    border-radius: 4px;
  }

  .side-panel-resizer {
    @media screen and (max-width: 800px) and (min-height: 500px) {
      flex-grow: 1 !important;
      width: 100%;
      height: auto !important;
      max-width: 100% !important;
      padding-bottom: 1em;
      // max-height: 50vh;
    }

    @media screen and (min-width: 1000px) and (min-height: 1000px) {
      height: 75% !important;
    }
  }

  .ResizePanel-module_ResizeContentHorizontal__1gGbA {
    @media screen and (max-width: 800px) and (min-height: 500px) {
      width: 100% !important;
    }
  }

  iframe#us–style-manager-1buttonIframe1624751726781.us–style-manager-1buttonIframe1624751726781 {
    transform: scale(0.8) !important;
  }

  // *************************************************************
  // react-toastify styling
  // *************************************************************

  .Toastify__toast--error {
    border: 1px solid ${Colors.pink};
    border-radius: 0.5rem !important;
    background: ${Colors.darkgrey} !important;
  }
  .Toastify__toast--error .Toastify__progress-bar {
    background-color: ${Colors.pink};
  }
  .Toastify__toast--success {
    border: 1px solid ${Colors.green} !important;
    border-radius: 0.5rem !important;
    background: ${Colors.green} !important;
  }
  .Toastify__toast--dark {
    border: 1px solid ${Colors.purple} !important;
    border-radius: 0.5rem !important;
    background: ${Colors.darkgrey} !important;
  }
  .Toastify__toast-body {
    font-family: 'IBM Plex Mono', sans-serif;
    color: ${Colors.white};
    font-size: 13px;
    line-height: 1.4;
    padding: 0 1em;
    width: 100%;
    font-weight: 400;
  }
  .Toastify__toast > button>  svg {
      display: none;
  }

  // *************************************************************
  // react-numeric-input styling
  // *************************************************************
  .react-numeric-input {
    input {
      background: ${Colors.grey};
      color: ${Colors.white};
      display: block;
      border: 0 !important;
      margin: 0.5em 0;
      padding: 0.4em 0.8em !important;
      font-size: 0.9em !important;
      line-height: 1;
      border-radius: 0.5em !important;
      outline: none;
    }
    b {
      box-shadow: none !important;
      background: transparent !important;
      i {
        margin: -0.6ex 0px 0px -0.56ex !important;
        border-color: ${Colors.white} transparent transparent !important;
      }
    }
    b:first-of-type {
      bottom: 2px;
      i {
        margin: -0ex 0px 0px -0.56ex !important;
        border-color: transparent transparent ${Colors.white} !important;
      }
    }
  }
`;
