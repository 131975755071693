import React, { useState } from 'react';
import styled from 'styled-components';
import { useMoralis } from 'react-moralis';
import Colors from '../../constants/Colors';
import Button from '../UI/Button';
import VenueFields from './VenueFields';

const ErrorText = styled.p`
  border: 1px solid ${Colors.red};
  padding: 1em;
  margin: 1em 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CreateVenue = ({ setToggleCreate, venue }) => {
  const { Moralis } = useMoralis();
  const [username, setUsername] = useState(venue?.attributes?.username || '');
  const [displayName, setDisplayName] = useState(venue?.attributes?.displayName || '');
  const [description, setDescription] = useState(venue?.attributes?.description || '');
  const [website, setWebsite] = useState(venue?.attributes?.website || '');
  const [phone, setPhone] = useState(venue?.attributes?.phone || '');
  const [address, setAddress] = useState(venue?.attributes?.address || '');
  const [ethAddress, setEthAddress] = useState(venue?.attributes?.ethAddress || '');
  const [streamUrl, setStreamUrl] = useState(venue?.attributes?.streamUrl || '');
  const [cover, setCover] = useState(venue?.attributes?.cover || '');
  const [errors, setErrors] = useState(false);
  const [apiError, setApiError] = useState('');

  const handleSetObject = async () => {
    if (username !== '') {
      setErrors(false);

      const Venue = Moralis.Object.extend('Venue');
      const venueObj = venue || new Venue();

      venueObj.set('username', username);
      venueObj.set('displayName', displayName);
      venueObj.set('description', description);
      venueObj.set('website', website);
      venueObj.set('phone', phone);
      venueObj.set('address', address);
      venueObj.set('ethAddress', ethAddress);
      venueObj.set('streamUrl', streamUrl);
      venueObj.set('cover', cover);

      venueObj.save()
        .then(() => {
          handleClose(); // close the modal
        }, (error) => {
          // Execute any logic that should take place if the save fails.
          // error is a Moralis.Error with an error code and message.
          setApiError(`Failed to create or update object, with error code: ${error.message}`);
        });
    } else {
      setErrors(true);
    }
  };

  const handleClose = () => {
    setToggleCreate(false);
  };

  return (
    <div>
      <VenueFields
        username={username}
        setUsername={setUsername}
        displayName={displayName}
        setDisplayName={setDisplayName}
        description={description}
        setDescription={setDescription}
        website={website}
        setWebsite={setWebsite}
        phone={phone}
        setPhone={setPhone}
        address={address}
        setAddress={setAddress}
        ethAddress={ethAddress}
        setEthAddress={setEthAddress}
        streamUrl={streamUrl}
        setStreamUrl={setStreamUrl}
        cover={cover}
        setCover={setCover}
      />

      {errors && <ErrorText>Required fields are missing.</ErrorText>}
      {apiError && <ErrorText>{apiError}</ErrorText>}

      <ButtonContainer>
        <Button onClick={handleSetObject}>
          {venue ? 'Update Venue' : 'Create Venue'}
        </Button>

        <Button type='secondary' onClick={handleClose}>
          Cancel
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default CreateVenue;
