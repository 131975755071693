import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { H1 } from '../components/UI/Heading';
import Main from '../components/UI/Main';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer';

const StyledIframe = styled.iframe`
  width: 100%;
  height: 60vh;
  border: 0;
`;

const Cookies = () => (
  <>
    <Helmet>
      <title>
        Cookie Policy - Shomego
      </title>
    </Helmet>
    <Navigation />
    <Main narrow>
      <H1>Cookie Policy</H1>
      <StyledIframe src='https://app.termly.io/document/cookie-policy/14427a36-8f66-419f-9f21-4bd89e32e5f0' />
    </Main>
    <Footer />
  </>
);

export default Cookies;
