import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import { useMoralis } from 'react-moralis';
import styled from 'styled-components';
// import Select from 'react-select';
import 'react-toggle/style.css';
import useLocalTracks from '../../hooks/useLocalTracks';
import useDevices from '../../hooks/useDevices';
// import useKeyPress from '../../hooks/useKeyPress';
import useModal from '../../hooks/useModal';
import { PartyContext } from '../../context/partyContext';
import Colors from '../../constants/Colors';
import Avatar from '../User/Avatar';
import { H2, H4 } from '../UI/Heading';
import { Select } from '../UI/Input';
import PartySlot from './PartySlot';
import { ReactComponent as VideoIcon } from '../../assets/icons/video.svg';
import { ReactComponent as AudioIcon } from '../../assets/icons/mic-on.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';

const MyContainer = styled.div`
  position: relative;
  width: 100%;
`;

// const Status = styled.div`
//   background: ${(props) => (props.active ? Colors.green : Colors.red)};
//   width: 1rem;
//   height: 1rem;
//   border-radius: 50%;
//   position: absolute;
//   top: 0.5rem;
//   right: 0.5rem;
// `;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
  object-position: center center;
  transform: scale(-1, 1);
  border-radius: 1rem;
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

// const IconsContainerTop = styled.div`
//   position: absolute;
//   top: 0.5em;
//   left: 0;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
// `;

const IconsContainerBottom = styled.div`
  position: absolute;
  bottom: 0.2em;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const IconContainer = styled.div`
  // background: ${(props) => (props.active ? Colors.green : Colors.red)};
  // ${(props) => props.standard && `background: ${Colors.darkgrey};}`}
  background: ${Colors.black};
  border-radius: 50%;
  display: ${(props) => (props.showHover ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.2em;
`;

const StyledVideoIcon = styled(VideoIcon)`
  width: 1.5em;
  height: 1.5em;
  color: ${(props) => (props.active ? Colors.green : Colors.pink)};
`;

const StyledAudioIcon = styled(AudioIcon)`
  width: 1.5em;
  height: 1.5em;
  color: ${(props) => (props.active ? Colors.green : Colors.pink)};
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  width: 1.5em;
  height: 1.5em;
  color: ${Colors.white};
`;

// const ShowOnHover = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   display: ${(props) => (props.active ? 'block' : 'none')};
// `;

const ConfigContainer = styled.div`
  min-width: 600px;
  max-width: 90%;
  min-height: 600px;
  max-height: 90%;
  padding: 1rem;
`;

const InputBox = styled.div`
  margin: 4rem 0;
`;

const VideoPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// const InputContainer = styled.div`
//   width: 60%;
// `;

// const VideoContainer = styled.div`
//   width: 35%;
// `;

const Me = ({ participantsCount }) => {
  const { user } = useMoralis();
  const {
    getLocalVideoTrack,
    getLocalAudioTrack,
    enableLocalVideoTrack,
    disableLocalVideoTrack,
    enableLocalAudioTrack,
    disableLocalAudioTrack,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    saveAudioInput,
    saveVideoInput
  } = useLocalTracks();
  const {
    audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices
  } = useDevices();
  const {
    party, localTracks, videoOn, setVideoOn, audioOn, setAudioOn
  } = useContext(PartyContext);
  // const happyPress = useKeyPress(' ');
  const videoRef = useRef();
  // const videoPreviewRef = useRef();
  const [showHover, setShowHover] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [MenuModal, openMenuModal] = useModal({
    preventScroll: true
  });
  const [videoInputValue, setVideoInputValue] = useState(window.localStorage.getItem('shomego-selectedVideoInput'));
  const [audioInputValue, setAudioInputValue] = useState(window.localStorage.getItem('shomego-selectedAudioInput'));

  const handleToggleVideo = () => {
    // console.log({ localTracks });
    /** Check to see if we have localTracks even setup yet. If not, set them up to enable */
    if (localTracks.length <= 0) {
      // console.log('found no tracks at all. setting one up.');
      getLocalVideoTrack();
      return false;
    }

    const foundVideoTrack = localTracks.find((track) => track?.kind === 'video');

    if (foundVideoTrack) {
      /** Handle the toggle  */
      if (videoOn) {
        disableLocalVideoTrack();
        setVideoOn(false);
      } else {
        enableLocalVideoTrack();
        setVideoOn(true);
      }
    } else {
      // console.log('found no video tracks. setting one up.');
      getLocalVideoTrack();
    }

    return true;
  };

  const handleToggleAudio = () => {
    /** Check to see if we have localTracks even setup yet. If not, set them up to enable */
    if (localTracks.length <= 0) {
      // console.log('found no tracks at all. setting one up.');
      getLocalAudioTrack();
      return false;
    }

    const foundAudioTrack = localTracks.find((track) => track?.kind === 'audio');

    if (foundAudioTrack) {
      /** Handle the toggle  */
      if (audioOn) {
        disableLocalAudioTrack();
        setAudioOn(false);
      } else {
        enableLocalAudioTrack();
        setAudioOn(true);
      }
    } else {
      // console.log('found no audio tracks. setting one up.');
      getLocalAudioTrack();
    }

    return true;
  };

  const handleToggleConfig = () => {
    openMenuModal();
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (localTracks[0] || localTracks[1]) {
      const foundVideoTrack = localTracks.find((track) => track?.kind === 'video');
      const videoElement = videoRef.current;

      if (foundVideoTrack) {
        // console.log('Attaching video track');
        foundVideoTrack.attach(videoElement);
      }
    }
  }, [localTracks]);

  const handleVideoChange = (e) => {
    removeLocalVideoTrack();
    setVideoInputValue(e.target.value);
    saveVideoInput(e.target.value);
  };

  const handleAudioChange = (e) => {
    removeLocalAudioTrack();
    setAudioInputValue(e.target.value);
    saveAudioInput(e.target.value);
  };

  return (
    <PartySlot
      name={user.get('displayName') || user.get('ethAddress').substring(0, 5)}
      audioOn={audioOn}
      participantsCount={participantsCount}
      onMouseEnter={() => setShowHover(true)}
      onMouseLeave={() => setShowHover(false)}
    >
      <MyContainer>
        <Avatar src={user.get('avatar')} />
        <Video ref={videoRef} active={videoOn} />
        {/* <Status active={audioOn} /> */}
        {party
        && (
        <IconsContainerBottom>
          <IconContainer standard showHover={showHover} onClick={handleToggleConfig}>
            <StyledSettingsIcon />
          </IconContainer>
          <IconContainer showHover onClick={handleToggleAudio} active={audioOn}>
            <StyledAudioIcon active={audioOn ? 1 : 0} />
          </IconContainer>
          <IconContainer showHover={showHover} onClick={handleToggleVideo} active={videoOn}>
            <StyledVideoIcon active={videoOn ? 1 : 0} />
          </IconContainer>
        </IconsContainerBottom>
        )}

        {/* <ShowOnHover active={party && showHover}>
          <IconsContainerTop>
            <IconContainer onClick={handleToggleVideo} active={videoOn}>
              <StyledVideoIcon active={videoOn ? 1 : 0} />
            </IconContainer>
            <IconContainer onClick={handleToggleConfig}>
              <StyledSettingsIcon />
            </IconContainer>
          </IconsContainerTop>
        </ShowOnHover> */}
      </MyContainer>

      <MenuModal>
        <ConfigContainer>
          <H2>Party Settings</H2>

          <InputBox>
            <H4>Camera</H4>
            <VideoPreview>
              {/* <InputContainer> */}
              {hasVideoInputDevices ? (
                <Select
                  onChange={handleVideoChange}
                  value={videoInputValue}
                >
                  {videoInputDevices.map((item) => (
                    <option key={item.deviceId} value={item.deviceId}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              ) : 'No video inputs found'}
              {/* </InputContainer> */}
              {/* <VideoContainer>
                <Video ref={videoPreviewRef} />
              </VideoContainer> */}
            </VideoPreview>

          </InputBox>

          <InputBox>
            <H4>Microphone</H4>
            {hasAudioInputDevices ? (
              <Select
                onChange={handleAudioChange}
                value={audioInputValue}
              >
                {audioInputDevices.map(
                  (item) => (
                    <option key={item.deviceId} value={item.deviceId}>
                      {item.label}
                    </option>
                  )
                )}
              </Select>
            ) : 'No audio inputs found'}
          </InputBox>
        </ConfigContainer>
      </MenuModal>
    </PartySlot>
  );
};

export default Me;
