import React, { useState } from 'react';
import styled from 'styled-components';
import { useMoralisQuery } from 'react-moralis';
import Colors from '../../constants/Colors';
import { H4 } from '../UI/Heading';
import {
  P, Italic
} from '../UI/Text';
import Link, { ExternalLink } from '../UI/Link';
import Loader from '../UI/Loader';
import Error from '../UI/Error';
import Button from '../UI/Button';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  // grid-gap: 5rem;

  @media screen and (max-width: 850px) {
    display: block;
  }
`;

const VenueItem = styled.div`
  position: relative;
  padding: 1em;
  // width: 45%;
  overflow: hidden;
`;

const VenueDetails = styled.div`
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const PosterContainer = styled.div`
  width: 25%;
  min-height: 100px;
  background: ${Colors.grey};
`;

const Poster = styled.img`
  width: 100%;
`;

const Details = styled.div`
  width: 70%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-right: 1em;
`;

const ListVenues = ({ handleUpdate }) => {
  const [limit] = useState(12);
  const { data, error, isLoading } = useMoralisQuery('Venue', (query) => query
    .limit(limit)
    .include('Venue', 'venueId')
    .descending('username'),
  [limit],
  {
    live: true,
  });

  const handleDelete = async (showObj) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this?')) {
      console.log(showObj);

      showObj.destroy().then(() => {
        // The object was deleted from the Moralis Cloud.
      }, (err) => {
        // The delete failed.
        // error is a Moralis.Error with an error code and message.
        console.error(err);
      });
    }
  };

  if (error) {
    return <Error label='Error' status={error} />;
  }

  if ((!data || data.length === 0) && !isLoading) {
    return 'No venues found';
  }

  return (
    isLoading ? <Loader />
      : (
        <Grid>
          {data.map((venue) => (
            <VenueItem key={venue.id}>
              <H4>
                <Link to={`@${venue.attributes.username}`}>
                  {venue.attributes.displayName}
                </Link>
              </H4>
              <VenueDetails>
                <PosterContainer>
                  {venue.attributes.cover && <Poster src={venue.attributes.cover} />}
                </PosterContainer>
                <Details>
                  <P>
                    URL:
                    {' '}
                    <a href={`@${venue.attributes.username}`} target='_blank' rel='noopener noreferrer'>
                      @
                      {venue.attributes.username}
                    </a>
                  </P>
                  <P>
                    Stream Playback URL:
                    {' '}
                    {venue.attributes.streamUrl}
                  </P>
                  <P>
                    Ethereum Wallet Address:
                    {' '}
                    <ExternalLink href={`https://etherscan.io/address/${venue.attributes.ethAddress}`}>
                      {venue.attributes.ethAddress}
                    </ExternalLink>
                  </P>
                  {venue.attributes.description && <P>{venue.attributes.description}</P>}
                  {venue.attributes.website
                  && (
                  <P>
                    <ExternalLink href={venue.attributes.website}>
                      {venue.attributes.website}
                    </ExternalLink>
                  </P>
                  )}
                  {venue.attributes.phone && <P>{venue.attributes.phone}</P>}
                  {venue.attributes.address && <P>{venue.attributes.address}</P>}
                  <P>
                    <Italic>
                      Created:&nbsp;
                      {new Date(venue.createdAt).toLocaleDateString(
                        {
                          year: 'numeric',
                          month: 'full',
                          day: 'long'
                        }
                      )}
                      &nbsp;at&nbsp;
                      {new Date(venue.createdAt).toLocaleTimeString(
                        {
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true
                        }
                      )}
                    </Italic>
                  </P>
                </Details>
              </VenueDetails>
              <ButtonContainer>
                <StyledButton type='secondary' onClick={() => handleUpdate(venue)}>
                  Edit
                </StyledButton>
                <Button type='secondary' onClick={() => handleDelete(venue)}>Delete</Button>
              </ButtonContainer>
            </VenueItem>
          ))}
        </Grid>
      )
  );
};

export default ListVenues;
