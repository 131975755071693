import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useMoralis } from 'react-moralis';
import Colors from '../constants/Colors';
import { H1 } from '../components/UI/Heading';
import Button from '../components/UI/Button';
import Box from '../components/UI/Box';
import Main from '../components/UI/Main';
import Error from '../components/UI/Error';
// import Header from '../components/Header';
import Navigation from '../components/Navigation/Navigation';
import Avatar from '../components/User/Avatar';

const StyledBox = styled(Box)`
  margin: 0 auto;
`;

const Inline = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Card = styled.div`
  border: 1px dotted ${Colors.grey};
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const AvatarContainer = styled.div`
  width: 50%;
  padding: 1em;
  // background: ${Colors.grey};
`;

const Profile = () => {
  const {
    logout, user, isAuthenticating, hasAuthError, authError
  } = useMoralis();

  return (
    <>
      <Helmet>
        <title>
          Your Profile - Shomego
        </title>
      </Helmet>
      <Navigation />
      <Main>
        <StyledBox>
          <Inline>
            <H1>Your Profile</H1>
            <Button onClick={() => logout()} disabled={isAuthenticating}>
              Logout
            </Button>
          </Inline>

          <Card>
            <AvatarContainer>
              <Avatar src={user.get('avatar')} style={{ width: '100%' }} />
              {hasAuthError && <Error label='Error' status={authError} />}
            </AvatarContainer>
          </Card>
        </StyledBox>
      </Main>
    </>
  );
};

export default Profile;
