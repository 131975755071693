import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { H2 } from '../components/UI/Heading';
import {
  P, UL, LI
} from '../components/UI/Text';
import Main from '../components/UI/Main';
import Hero from '../components/UI/Hero';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer';
import { ButtonLinkInternal } from '../components/UI/Button';
import { Colors } from '../constants';
// import Input from '../components/UI/Input';

const Narrow = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Section = styled.div`
  margin: 8em 0;

  @media screen and (max-width: 600px) {
    margin: 4em 0;
  }
`;

const VenueBox = styled.div`
  border: 1px solid ${Colors.grey};
  border-radius: 1.5rem;
  padding: 2em;

  @media screen and (max-width: 600px) {
    margin: 4em 0 0;
  }
`;

// const VenueButton = styled(Button)`
//   margin-top: 1rem;
// `;

const About = () => (
  <>
    <Helmet>
      <title>
        About - Shomego
      </title>
    </Helmet>
    <Navigation />
    <Main>
      <Hero
        headline='Changing the game for independent live music'
        intro='Local venues and independent artists are the heart and soul of our cities. Shomego brings
          the local venue going experience online for fans to experience from anywhere in the world.'
      >
        <VenueBox>
          <H2>
            Hey, venue owners!
          </H2>
          <P>
            Reach out to us and let us know you&rsquo;re interested in getting your venue setup
            and running on Shomego.
          </P>
          <ButtonLinkInternal type='submit' href='mailto:chad@shomego.com'>
            I&rsquo;m interested
          </ButtonLinkInternal>
          {/* <form name='venue-interested' method='POST' data-netlify='true'>
            <Input type='text' name='venue-name' placeholder='Venue name' required />
            <Input type='email' name='email' placeholder='Your email' required />
            <VenueButton type='submit'>I&rsquo;m interested</VenueButton>
          </form> */}
        </VenueBox>
      </Hero>
      <Narrow>
        <Section>
          <H2>Mission</H2>
          <P>
            First and foremost, we love independent artists and local venues. Plain and simple.
          </P>
          <P>
            These artists and venues are underserved by technology. So we’re doing something about
            it. We’re helping them to reach new fans, make more money, all while streamlining the
            way they get paid.
          </P>
          <P>
            If you are vaguely familiar with the music industry you probably know that artists
            make their money through live performances. For independent artists, those
            performances typically happen at local venues. You know, the ones that make our
            cities cool and where we create memories.
          </P>
          <P>
            Shomego is bringing these venues online with permanent, state of the art A/V networks
            to stream all their shows on the Shomego platform
            {' '}
            <em>(at no cost to the venue)</em>
            . This gives them and the artists who play on their stages the ability to reach
            millions of people at any given time.
          </P>
          <P>
            Yeah, yeah. We get it. Live music streaming. Not new. meh.
          </P>
          <P>
            But here’s what is. Shomego is a decentralized platform, enabling fans to pay
            venues and artists directly and transparently for tickets, tips and merch.
          </P>
          <UL>
            <LI>
              Artists and venues reach more people and create new revenue streams.
            </LI>
            <LI>
              People get live music every night of the week from anywhere in the world.
            </LI>
            <LI>
              Payments are direct and transparent.
            </LI>
            <LI>
              We keep our punk rock spirit.
            </LI>
          </UL>
        </Section>
      </Narrow>
    </Main>
    <Footer />
  </>
);

export default About;
