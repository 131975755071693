import React from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import Avatar from '../User/Avatar';
import ReactTooltip from 'react-tooltip';

const TooltipContainer = styled.div`
  width: 30rem;
`;

const TwoCol = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AvatarContainer = styled.div`
  width: 50%;
`;

const InfoContainer = styled.div`
  width: 50%;
  padding-left: 1em;
`;

const TTName = styled.div`
  font-size: 1.3em;
  border-bottom: 1px solid ${Colors.grey};
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
`;

const Me = ({
  id, avatar, username, children
}) => (
  <ReactTooltip effect='solid' id={id} clickable delayHide={100} className='tooltip-theme'>
    <TooltipContainer>
      <TwoCol>
        <AvatarContainer>
          {avatar && <Avatar src={avatar} />}
        </AvatarContainer>
        <InfoContainer>
          <TTName>
            {username}
          </TTName>
          {children}
        </InfoContainer>
      </TwoCol>
    </TooltipContainer>
  </ReactTooltip>
);

export default Me;
