import React from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import DefaultAvatar from '../../assets/images/avatar-default.png';

const AvatarContainer = styled.div`
  position: relative;
  margin: 0 auto;
  background: ${Colors.darkgrey};
  // overflow: hidden;
  border-radius: ${(props) => (props.circle ? '50%' : '1rem')};
`;

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  border-radius: ${(props) => (props.circle ? '50%' : '1rem')};
`;

const Avatar = ({
  src, circle, className
}) => (
  <AvatarContainer className={className}>
    <StyledImage src={src || DefaultAvatar} circle={circle} />
  </AvatarContainer>
);

export default Avatar;
