const white = '#F2F0F0';
const black = '#020205';
const pureblack = '#000000';
const grey = '#272427';
const lightestgrey = '#C6E4E2';
const lightgrey = '#7c8282';
const darkgrey = '#121212';
const blue = '#3E8DEB';
const deepblue = '#0F0A2D';
const purple = '#bb86fc';
const deeppurple = '#37102E';
const burgundy = '#510418';
const deepburgundy = '31041C';
const red = '#D10643';
const pink = '#f40350';
const gold = '#C9A611';
const orange = '#FFAA3A';
const lightorange = '#FFB95C';
const darkorange = '#BC7314';
const green = '#92D18F';

export default {
  white,
  black,
  pureblack,
  grey,
  lightestgrey,
  lightgrey,
  darkgrey,
  blue,
  deepblue,
  purple,
  deeppurple,
  burgundy,
  deepburgundy,
  red,
  pink,
  gold,
  orange,
  lightorange,
  darkorange,
  green,

  /** GLOBAL COLORS REGARDLESS OF THEME */
  errorBackground: red,
  errorText: white,
};
