import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2.5rem;
`;

const Container = ({ className, children }) => (
  <StyledContainer className={className}>
    {children}
  </StyledContainer>
);

export default Container;
