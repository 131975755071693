import React from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import Colors from '../../constants/Colors';

export const H1 = styled.h1`
  font-family: 'Rebar', 'IBM Plex Mono', sans-serif;
  font-size: 3.2em;
  line-height: 1.1;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 2rem;
  text-shadow: 0 0 1em ${hexToRgba(Colors.deeppurple, '0.6')}, 0 0 1em ${hexToRgba(Colors.white, '0.4')};

  @media screen and (max-width: 900px) {
    font-size: 2.8em;
  }

  @media screen and (max-width: 600px) {
    font-size: 2.3em;
  }
`;

export const H2 = styled.h2`
  font-family: 'Rebar', 'IBM Plex Mono', sans-serif;
  font-size: 1.8em;
  font-weight: 300;
  line-height: 1.2;
  margin: 0 0 0.7em;
  // text-transform: uppercase;
`;

export const H3 = styled.h3`
  font-family: 'Rebar', 'IBM Plex Mono', sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
  margin: 0 0 1rem;
  text-transform: uppercase;
`;

export const H4 = styled.h4`
  font-family: 'Rebar', 'IBM Plex Mono', sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 1rem;
  text-transform: uppercase;
`;

export const H5 = styled.h5`
  font-family: 'Rebar', 'IBM Plex Mono', sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 1rem;
  text-transform: uppercase;
`;

const HeadingContainer = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 2rem;

  * {
    margin-bottom: 0;
  }
`;

const Spacer = styled.div`
  height: 1px;
  background: ${Colors.grey};
  margin-left: 1em;
`;

const Heading = ({ children }) => (
  <HeadingContainer>
    {children}
    <Spacer />
  </HeadingContainer>
);

export default Heading;
