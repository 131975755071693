import React, { useContext } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import Countdown from 'react-countdown';
import { ShowContext } from '../../context/showContext';
import Colors from '../../constants/Colors';
import { H1 } from '../UI/Heading';
import { P } from '../UI/Text';
import VenueInfo from './VenueInfo';

const ExpandedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
`;

const PosterContainer = styled.div`
  width: 40%;
  margin-bottom: 2em;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const Poster = styled.img`
  display: block;
  border-radius: 1rem;
  width: 100%;
`;

const ExpandedDetails = styled.div`
  width: 55%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const ShowTitle = styled(H1)`
  // color: ${Colors.lightorange};
  font-size: 2em;
`;

const ShowDescription = styled.div`
`;

const Price = styled(P)`
  margin-top: 1em;
`;

const StartDate = styled(P)``;

const Ended = styled(P)`
  margin-top: 1em;
  text-transform: uppercase;
  color: ${Colors.pink};
`;

const Timer = styled.div`
  color: ${Colors.pink};
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 1em;
`;

const ShowInfo = ({ expanded }) => {
  const { showDetails } = useContext(ShowContext);

  const isShowEnded = new Date() >= new Date(showDetails?.attributes?.endDate).getTime();

  if (!showDetails) {
    return null;
  }

  return (
    <div>

      {expanded ? (
        <ExpandedContainer>
          <ExpandedDetails>
            <ShowTitle>{showDetails.attributes.name}</ShowTitle>
            {showDetails.attributes.venue
              && <VenueInfo venueId={showDetails.attributes.venue.id} />}

            <Price>Price: FREE</Price>

            {isShowEnded ? (
              <Ended>
                This show has ended
              </Ended>
            ) : (
              <>
                <StartDate>
                  {dayjs(showDetails.attributes.startDate).format('dddd, MMMM DD, YYYY [at] h:mmA [CDT]')}
                </StartDate>

                <Countdown
                  date={new Date(showDetails.attributes.startDate).getTime()}
                  renderer={({
                    days, hours, minutes, seconds, completed
                  }) => {
                    if (completed) {
                      window.location.reload();
                    }
                    return (
                      (
                        <>
                          <Timer>
                            {days > 0 && `${days} days, `}
                            {hours > 0 && `${hours} hours, `}
                            {minutes > 0 && `${minutes} minutes, `}
                            {`${seconds} seconds`}
                          </Timer>
                        </>
                      )
                    );
                  }}
                />
              </>
            )}

            <ShowDescription>
              {parse(showDetails.attributes.description)}
            </ShowDescription>
          </ExpandedDetails>

          {showDetails.attributes.poster && (
            <PosterContainer><Poster src={showDetails.attributes.poster} /></PosterContainer>
          )}
        </ExpandedContainer>
      )

        : (
          <>
            <ShowTitle>{showDetails.attributes.name}</ShowTitle>
            {showDetails.attributes.venue
              && <VenueInfo venueId={showDetails.attributes.venue.id} />}
          </>
        )}
    </div>
  );
};

export default ShowInfo;
