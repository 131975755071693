import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { H1 } from '../components/UI/Heading';
import Main from '../components/UI/Main';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer';

const StyledIframe = styled.iframe`
  width: 100%;
  height: 60vh;
  border: 0;
`;

const Privacy = () => (
  <>
    <Helmet>
      <title>
        Privacy Policy - Shomego
      </title>
    </Helmet>
    <Navigation />
    <Main narrow>
      <H1>Privacy Policy</H1>
      <StyledIframe src='https://app.termly.io/document/privacy-policy/2ecf2ddf-9d5a-4cdd-ad11-81ec5c5a60ed' />
    </Main>
    <Footer />
  </>
);

export default Privacy;
