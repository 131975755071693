import React from 'react';
import { Helmet } from 'react-helmet-async';
import { H1, H4 } from '../components/UI/Heading';
import { P } from '../components/UI/Text';
import Main from '../components/UI/Main';
// import Header from '../components/Header';
import Navigation from '../components/Navigation/Navigation';

const NotFound = () => (
  <>
    <Helmet>
      <title>
        Not Found - Shomego
      </title>
    </Helmet>
    {/* <Header /> */}
    <Main>
      <H4>404</H4>
      <H1>Not Found</H1>
      <P>
        You must be lost. Or maybe we are? Either way, you won&rsquo;t find
        what you&rsquo;re looking for here.
      </P>
    </Main>
    <Navigation />
  </>
);

export default NotFound;
