import React from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';

const Wrapper = styled.div`
  position: relative;
  width: 11.5%;
  margin: 1%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    width: ${(props) => (props.participantsCount > 6 ? '23%' : '31%')};
  }
`;

const Slot = styled.div`
  flex: 1;
  border-radius: 1rem;
  border: 1px solid ${Colors.grey};
`;

const NameContainer = styled.div`
  min-height: 2rem;
  margin-top: 0.5rem;
  text-align: center;
  width: 100%;
  font-size: 0.7em;
  color: ${Colors.lightestgrey};
  user-select: none;
`;

const PartySlot = ({
  name, audioOn, onMouseEnter, onMouseLeave, participantsCount, children
}) => (
  <Wrapper
    participantsCount={participantsCount}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <Slot
      audioOn={audioOn}
    >
      {children}
    </Slot>
    <NameContainer>{name}</NameContainer>
  </Wrapper>
);

export default PartySlot;
