import React from 'react';
import styled, { keyframes } from 'styled-components';
import Colors from '../../constants/Colors';

const StyledError = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 1em;
`;

const Status = styled.div`
  font-size: 1em;
  line-height: 1;
  margin-right: 0.5em;
`;

const Flash = keyframes`
  0% {
    background: transparent;
  }

  100% {
    background: ${Colors.red};
  }
`;

const FlashingError = styled.div`
  display: inline-block;
  position: relative;
  line-height: 1;
  color: ${Colors.white};
  margin-bottom: 1rem;
  z-index: 1;
  padding: 0 0.3rem;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${Colors.red};
    opacity: 0.8;
    animation: ${Flash} 0.75s ease-in-out alternate infinite;
  }
`;

const Error = ({
  status, label, className, children
}) => (
  <StyledError className={className}>
    <Status>
      {label && `${label}: `}
      <FlashingError>{status}</FlashingError>
    </Status>
    {children}
  </StyledError>
);

export default Error;
