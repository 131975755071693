import React, { useState } from 'react';
import styled from 'styled-components';
import { useMoralisFile } from 'react-moralis';
import { Label } from '../UI/Text';
import Input from '../UI/Input';
import Button from '../UI/Button';
import Error from '../UI/Error';
import Loader from '../UI/Loader';

const InputContainer = styled.div`
  margin-bottom: 2em;
`;

const InlineInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
`;

const StyledButton = styled(Button)`
  margin-left: 1em;
  padding: 0.5em;
`;

const Poster = styled.img`
  width: 100%;
`;

const UploadPoster = ({ poster, setPoster }) => {
  const {
    error,
    isUploading,
    saveFile,
  } = useMoralisFile();
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [inputError, setInputError] = useState(null);

  const handleFileInput = (event) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const handleUpload = async () => {
    if (!file || !fileName) {
      setInputError('Select a file');
      return false;
    }
    setInputError(null);

    const savedFile = await saveFile(fileName, file, { saveIPFS: true });

    if (savedFile) {
      setPoster(savedFile?._ipfs);
    }

    return true;
  };

  return (
    <InputContainer>
      {poster && <Poster src={poster} />}
      {isUploading && <Loader />}
      {error && <Error label='Error' status={error} />}
      {inputError && <Error label='Error' status={inputError} />}
      <Label>
        Poster:
      </Label>
      <InlineInput>
        <Input type='file' onChange={handleFileInput} />
        <StyledButton type='secondary' onClick={handleUpload} disabled={isUploading || error}>
          Upload
        </StyledButton>
      </InlineInput>
    </InputContainer>
  );
};

export default UploadPoster;
