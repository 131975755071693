import React from 'react';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
import hexToRgba from 'hex-to-rgba';
import Colors from '../../constants/Colors';
import styled, { keyframes } from 'styled-components';

const allButtonCSS = `
  display: inline-block;
  color: ${Colors.black};
  background-color: ${Colors.purple};
  border: 2px solid transparent;
  margin: 0;
  padding: 0.6em 0.8em;
  text-decoration: none;
  font-size: 0.9em;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: none;
  border-radius: 0.2em;

  &:hover {
    color: ${Colors.deeppurple};
    box-shadow: 0 0 15px ${hexToRgba(Colors.purple, '0.8')};
  }
`;

const secondary = `
  display: inline-block;
  color: ${hexToRgba(Colors.white, '0.9')};
  background-color: ${Colors.grey};
  border: 2px solid transparent;
  margin: 0;
  padding: 0.6em 0.8em;
  text-decoration: none;
  font-size: 0.9em;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0.2em;

  &:hover {
    color: ${Colors.white};
    border-color: ${lighten(0.05, Colors.grey)};
    box-shadow: 0 5px 15px ${hexToRgba(Colors.grey, '0.4')};
    text-shadow:
      0 0 20px ${hexToRgba(Colors.white, '0.4')};
  }
`;

const tertiary = `
  display: inline-block;
  color: ${Colors.black};
  background-color: ${Colors.purple};
  border: 1px solid ${Colors.purple};
  margin: 0;
  padding: 0.6em 0.8em;
  text-decoration: none;
  font-size: 0.8em;
  line-height: 1.1;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: ${Colors.black};
    box-shadow: 0 5px 15px ${hexToRgba(Colors.grey, '0.4')};
  }
`;

const outline = `
  display: inline-block;
  color: ${Colors.purple};
  background-color: transparent;
  border: 1px solid ${Colors.purple};
  margin: 0;
  padding: 0.6em 1em;
  text-decoration: none;
  font-size: 0.8em;
  line-height: 1.1;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0.1em;

  &:hover {
    background-color: ${Colors.purple};
    color: ${Colors.black};
    box-shadow: 0 0 6px 2px ${hexToRgba(Colors.purple, '0.3')};
  }
`;

const Glitchy = keyframes`
  0%   {transform: translate(-2px, 2px);}
  25%  {transform: translate(-2px, -2px);}
  50%  {transform: translate(2px, 2px);}
  75%  {transform: translate(2px, -2px);}
  100%  {transform: translate(-2px, 2px);}
`;

const glow = keyframes`
  from {
    text-shadow:
    0 0 4px ${hexToRgba(Colors.white, '0.92')},
    0 0 1px ${hexToRgba(Colors.white, '0.34')},
    0 0 10px ${hexToRgba(Colors.pink, '0.05')},
    0 0 8px ${hexToRgba(Colors.pink, '0.10')},
    0 0 5px ${hexToRgba(Colors.pink, '0.78')},
    0 0 2px ${hexToRgba(Colors.pink, '0.92')};
  }

  to {
    text-shadow:
    0 0 3px ${hexToRgba(Colors.white, '0.98')},
    0 0 1px ${hexToRgba(Colors.white, '0.42')},
    0 0 8px ${hexToRgba(Colors.pink, '0.10')},
    0 0 10px ${hexToRgba(Colors.pink, '0.05')},
    0 0 5px ${hexToRgba(Colors.pink, '0.88')},
    0 0 2px ${hexToRgba(Colors.pink, '1')};
  }
`;

const InnerLink = styled.span`
  display: block;
  height: 100%;
  background-color: ${Colors.black};
  color: ${Colors.orange};
  padding: 0.7em 1em;
`;

const GlitchyButton = styled(Link)`
  position: relative;
  z-index: 1;
  display: inline-block;
  background: ${Colors.black};
  border: 2px solid ${Colors.orange};
  border-radius: 1px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1;

  &:before,
  &:after {
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:before {
    // background-color: #00ffff;
    // background-color: ${lighten(0.1, Colors.pink)};
    background-color: ${Colors.red};
    z-index: -1;
  }

  &:after {
    // background-color: #ff00ff;
    // background-color: ${lighten(0.2, Colors.red)};
    background-color: ${Colors.blue};
    z-index: -2;
  }

  &:hover {
    border-color: ${Colors.pink};

    ${InnerLink} {
      animation: ${Glitchy} 0.3s ease 1;
      color: ${Colors.pink};
      // box-shadow:
      //   0 0 0.5rem 0.1rem ${lighten(0.1, Colors.pink)},
      //   0 0 0.5rem black;
      // text-shadow:
      //   0 0 20px ${hexToRgba(Colors.pink, '0.4')},
      //   0 0 5px ${hexToRgba(Colors.pink, '0.8')};
      // animation: ${glow} 2s ease-in-out infinite alternate;
    }

    &:before {
      animation: ${Glitchy} 0.3s ease 0.3s 2;
    }

    &:after {
      animation: ${Glitchy} 0.3s ease 2 reverse;
    }
  }
`;

export const GlitchButton = ({ children, to, className }) => (
  <GlitchyButton to={to} className={className}>
    <InnerLink>{children}</InnerLink>
  </GlitchyButton>
);

const StyledButtonLink = styled(Link)`
  ${(props) => (props.type === 'secondary' ? secondary : allButtonCSS)}
`;

export const ButtonLink = ({
  children, to, className, type
}) => (
  <StyledButtonLink to={to} className={className} type={type}>
    {children}
  </StyledButtonLink>
);

const StyledButtonLinkExternal = styled.a`
  ${(props) => (props.type === 'secondary' ? secondary : allButtonCSS)}
`;

export const ButtonLinkInternal = ({ children, href, className }) => (
  <StyledButtonLinkExternal
    href={href}
    className={className}
  >
    {children}
  </StyledButtonLinkExternal>
);

export const ButtonLinkExternal = ({ children, href, className }) => (
  <StyledButtonLinkExternal
    href={href}
    className={className}
    target='_blank'
    rel='noreferrer'
  >
    {children}
  </StyledButtonLinkExternal>
);

const StyledButton = styled.button`
  ${(props) => (props.type === 'secondary' && secondary)}
  ${(props) => (props.type === 'tertiary' && tertiary)}
  ${(props) => (props.type === 'outline' && outline)}
  ${(props) => ((!props.type || props.type === 'submit') && allButtonCSS)}
  ${(props) => props.disabled
    && `opacity: 0.4;
    cursor: not-allowed;
    color: ${Colors.white};
    border-color: ${Colors.lightgrey};

    &:hover {
      color: ${Colors.white};
      border-color: ${Colors.lightgrey};
      box-shadow: none;
      text-shadow: none;
    }
    `}
`;

export const ButtonOutline = styled.button`
  ${allButtonCSS}
  border: 0.2em solid ${Colors.white};
  background: transparent;

  &:hover {
    border-color: ${Colors.red};
    background: ${Colors.red};
  }
`;

const Button = ({
  type, disabled, onClick, children, className
}) => (
  <StyledButton onClick={onClick} className={className} type={type} disabled={disabled}>
    {children}
  </StyledButton>
);

export default Button;
