/** Animations */
const duration = '400ms';
const easing = 'cubic-bezier(.03,.98,.52,.99)';
const transition = `${duration} ${easing}`;

/** Shadows */
const shadow = '0 3px 10px rgba(0, 0, 0, 0.5)';

/** Borders */
const radiusSmall = '0.5rem';
const radius = '1rem';
const radiusLarge = '2rem';

export default {
  duration,
  easing,
  transition,
  shadow,
  radiusSmall,
  radius,
  radiusLarge
};
