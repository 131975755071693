import React from 'react';
import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';
import Navigation from '../components/Navigation/Navigation';
// import BrowseFeatured from '../components/Browse/Featured';
// import BrowseUpcoming from '../components/Browse/Upcoming';
// import BrowsePast from '../components/Browse/Past';
import Main from '../components/UI/Main';
import { H1 } from '../components/UI/Heading';
import { P } from '../components/UI/Text';
import Footer from '../components/Footer';
import Placeholder from '../assets/images/home-poster.png';

const HomeHero = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 4em 0 12em;

  @media screen and (max-width: 600px) {
    padding: 2em 0;
  }
`;

const HeroLeft = styled.div`
  width: 50%;

  @media screen and (max-width: 900px) {
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const HeroRight = styled.div`
  width: 50%;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0rem;

  @media screen and (max-width: 900px) {
    width: 70%;
    right: 2rem;
    top: 4rem;
  }
`;

const Intro = styled(P)`
  font-size: 1.2em;
`;

const StreamContainer = styled.div`
  border-radius: 1rem;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  max-width: 100%;

  transform-origin: right center;
  transform: perspective(120px) rotateY(-2deg) translatez(0) translatex(-80px) scale(1.6);
  z-index: 0;
  will-change: -webkit-mask-position,-moz-mask-position,mask-position,transform;
  overflow: hidden;
  mask-image: radial-gradient(circle at 50% 50%,black 10%,rgba(0,0,0,0.1) 50%);
  mask-position: 0% 50%;
  mask-repeat: no-repeat;
  mask-size: 200% 200%;
  right: -80px;

  > div {
    width: 100%;
    height: 100%;
  }

  iframe,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Home = () => (
  <>
    <Navigation />
    <Main>
      {/* <BrowseFeatured /> */}
      <HomeHero>
        <HeroLeft>
          <H1>Support local venues and artists around the world</H1>
          <Intro>
            Watch live shows, chat with the audience, start a party
            with friends, and tip your favorite artists and venues.
          </Intro>
        </HeroLeft>
        <HeroRight>
          <StreamContainer>
            <img src={Placeholder} alt='' />
            {/* <iframe
              src='https://livepeercdn.com/asset/5ce0t4pdkri8kat1/video?loop=1'
              frameBorder='0'
              allow='autoplay; encrypted-media; picture-in-picture'
              sandbox='allow-scripts'
              controls={false}
              title='Livepeer Stream'
            /> */}
            <Vimeo
              video='https://vimeo.com/614022738/2452c4459c'
              background
              autoplay
              muted
              controls={false}
              loop
            />
          </StreamContainer>
        </HeroRight>
      </HomeHero>

      {/* <BrowseUpcoming /> */}

      {/* <BrowsePast /> */}
    </Main>
    <Footer />
  </>
);

export default Home;
