import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { ReactComponent as SoundIcon } from '../../assets/icons/sound-on.svg';

const VolumeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const Control = styled.div`
  width: 100%;
`;

const VolumeControl = ({ participant }) => {
  const [volume, setVolume] = useState(0.8);
  const [toggle, setToggle] = useState(false);

  if (!participant) return null;

  const handleVolumeChange = (value) => {
    setVolume(parseFloat(value).toFixed(2));

    const vid = document.getElementById(`${participant.username}-audio`);
    vid.volume = parseFloat(value).toFixed(2);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <VolumeContainer key={participant.identity} open={toggle}>
      <SoundIcon style={{ width: '2.5rem', display: 'block' }} onClick={handleToggle} />

      <Control open={toggle}>
        <Slider
          value={volume}
          min={0}
          max={1}
          step={0.01}
          orientation='horizontal'
          onChange={handleVolumeChange}
        />
      </Control>
    </VolumeContainer>
  );
};

export default VolumeControl;
