import { useEffect, useState, useCallback } from 'react';
import { useMoralis } from 'react-moralis';

/**
 * Returns true or false if a role is attached to a user
 *
 * @param {string} name The name of the role, ex: 'Admininstrator'
 * @return {bool} Found a matching a string or not
 */
const useRole = (name) => {
  const {
    Moralis,
    isAuthenticated,
    user,
  } = useMoralis();
  // const { data } = useMoralisQuery(Moralis.Role, (query) => query
  //   .equalTo('users', user)
  //   .find());

  const [roles, setRoles] = useState([]);
  const [isRole, setIsRole] = useState(false);

  const getRole = useCallback(async () => {
    if (isAuthenticated) {
      const foundRoles = await new Moralis.Query(Moralis.Role).equalTo('users', user).find();
      // console.log(foundRoles);

      if (foundRoles && foundRoles.length > 0) {
        foundRoles.forEach((item) => {
          setRoles(...roles, item.attributes.name);
          if (item.attributes.name === name) {
            setIsRole(true);
          }
        });
      }
    }
  }, [Moralis, name, roles, user, isAuthenticated]);

  useEffect(() => {
    getRole();
  }, [getRole]);

  return isRole;
};

export default useRole;
