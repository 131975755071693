import React, {
  useState, useContext, useEffect, useCallback
} from 'react';
import styled from 'styled-components';
import { useMoralis } from 'react-moralis';
import { PartyContext } from '../../context/partyContext';
import { ShowContext } from '../../context/showContext';
import { SocketContext } from '../../context/socketContext';
import Colors from '../../constants/Colors';
import Button from '../UI/Button';
import { H2 } from '../UI/Heading';
import { P, Strong } from '../UI/Text';
import Modal from '../UI/Modal';
import Error from '../UI/Error';
import Avatar from '../User/Avatar';

const Row = styled.div`
  font-size: 0.9em;
  overflow: auto;
  border-bottom: 1px solid ${Colors.green};
  padding-bottom: 1em;
  margin-bottom: 1em;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const LeftCol = styled.div`
  width: 40%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const RightCol = styled.div`
  width: 50%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const ListContainer = styled.div`
  // border: 1px solid ${Colors.green};
  // padding: 1rem;
  max-height: 30vh;
  overflow: auto;
`;

const PartyLink = styled.div`
  color: ${Colors.green};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 1em;

  &:hover {
    color: ${Colors.gold};
  }
`;

const Full = styled.div`
  color: ${Colors.lightestgrey};
  margin-right: 1em;
`;

const NoParties = styled.div`
  color: ${Colors.lightgrey};
`;

const ParticipantsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 0.5em;
  max-width: 100%;
`;

const PartyMember = styled.div`
  width: 80px;
  min-height: 90px;
`;

const ButtonContainer = styled.div`
  margin-top: 1em;
`;

const SmallName = styled.div`
  font-size: 0.8em;
`;

const PSmall = styled(P)`
  font-size: 0.8em;
  font-style: italic;
`;

const PartyList = ({
  togglePartyList, handleTogglePartyList
}) => {
  const {
    setupParty, party, setParty, isConnecting
  } = useContext(PartyContext);
  const { showDetails } = useContext(ShowContext);
  const { socket } = useContext(SocketContext);
  const { user } = useMoralis();
  // const [userHasParty, setUserHasParty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [partyList, setPartyList] = useState([]);
  const MAXPARTYSIZE = 8;

  const handleCreate = () => {
    /** partyId is null if we are creating a new party */
    setupParty(showDetails.id, null, user.id);
    handleTogglePartyList();
  };

  const handleJoin = useCallback((partyId, count) => {
    /** Only join a party if you're not already connecting to one or already in the party clicked */
    if (!isConnecting && party?.id !== partyId) {
      if (!count || count < MAXPARTYSIZE) {
        // console.log(partyId);
        setupParty(showDetails.id, partyId, user.id);
      }
      handleTogglePartyList();
    }
  }, [isConnecting, party, showDetails, user, setupParty, handleTogglePartyList]);

  const handleLeave = () => {
    setParty(null);
  };

  useEffect(() => {
    let loaded = false;
    if (togglePartyList && socket && !loaded) {
      setLoading(true);

      socket.emit('listParties', {
        showId: showDetails.id
      });

      socket.on('allParties', (message) => {
        if (!message) {
          setError(true);
        }
        setPartyList(message);
      });

      loaded = true;

      setLoading(false);
    }

    return () => {
      if (socket && loaded) {
        setPartyList([]);
      }
    };
  }, [togglePartyList, socket, showDetails, setPartyList]);

  // useEffect(() => {
  //   if (togglePartyList && partyList && partyList.length) {
  //     const uuids = partyList.map(
  //       (partyItem) => partyItem.users.map((partyUser) => partyUser.uuid)
  //     );
  //     const uuid = user[uuidUri];

  //     console.log('checking for party ownership');

  //     uuids.map((item) => item.includes(uuid) && setUserHasParty(true));
  //   }

  //   return () => setUserHasParty(false);
  // }, [togglePartyList, partyList, user, uuidUri]);

  const Listing = useCallback(({ data }) => {
    if (error) return <Error status='ERROR'>{error}</Error>;

    return (
      data && data.length > 0
        ? data.map((partyItem) => (
          <Row key={partyItem.id}>
            {partyItem.count < MAXPARTYSIZE
              ? (
                <PartyLink onClick={() => handleJoin(partyItem.id, partyItem.count)}>
                  {/* {partyItem.name}
                  {' '} */}
                  (
                  {partyItem.count}
                  )
                  {' '}
                  {party?.id !== partyItem.id ? 'Join this party' : 'Your party'}
                </PartyLink>
              )
              : (
                <Full>
                  {/* {partyItem.name}
                  {' '} */}
                  FULL
                </Full>
              )}
            <ParticipantsContainer>
              {partyItem.users.map((partyUser) => (
                <PartyMember key={partyUser.username}>
                  <Avatar
                    src={partyUser.avatar}
                    alt={partyUser.username}
                  />
                  <SmallName>{partyUser.username}</SmallName>
                </PartyMember>
              ))}
            </ParticipantsContainer>
          </Row>
        ))
        : <NoParties>No parties found.</NoParties>
    );
  }, [error, party, handleJoin]);

  // const CreateButton = () => (
  //   userHasParty ? 'You already have a party'
  //     : (
  //       <Button onClick={() => handleCreate()}>
  //         Start Your Own Party
  //       </Button>
  //     )
  // );

  if (!togglePartyList) {
    return false;
  }

  return (
    <Modal
      isOpen={togglePartyList}
      close={handleTogglePartyList}
      title='Parties'
      width='80rem'
    >
      <Columns>
        <LeftCol>
          <H2>Party Time</H2>
          <P>
            Get the full live experience by creating or joining a party. Video chat and socialize
            like you were at the show.
          </P>
          <P>For the best party experience, use a laptop and headphones.</P>
          <PSmall>
            Note: All parties, including the ones you create, are
            {' '}
            <Strong>public</Strong>
            . We will be adding new features soon to allow private parties, inviting
            others to parties, searching and more.
          </PSmall>
        </LeftCol>

        <RightCol>
          <H2>Open Parties</H2>
          {loading ? 'Loading parties...' : <ListContainer><Listing data={partyList} /></ListContainer>}

          <ButtonContainer>
            {!loading && !party?.id && (
            <Button onClick={() => handleCreate()}>
              Start a New Party
            </Button>
            )}
            {party?.id && (
            <Button onClick={() => handleLeave()}>
              <span role='img' aria-label='leave'>
                Leave Your Party
              </span>
            </Button>
  )}
          </ButtonContainer>
        </RightCol>
      </Columns>
    </Modal>
  );
};

export default PartyList;
