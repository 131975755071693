import React from 'react';
import styled from 'styled-components';
// import Colors from '../constants/Colors';
// import Layout from '../constants/Layout';
import { createPortal } from 'react-dom';
import Box from './Box';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  padding: 1em;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  cursor: cancel;
  background: rgba(0, 0, 0, 0.9);
`;

const Foreground = styled.div`
  z-index: 101;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 500ms ease-in-out;
  max-width: 100%;
`;

// const Container = styled.div`
//   position: relative;
//   z-index: 100001;
//   border: 2px solid ${Colors.white};
//   box-shadow: ${Layout.shadow};
//   padding: 2em;
// `;

const Modal = ({
  children,
  isOpen = false,
  close,
  allowBackgroundClose = true,
  elementId = 'root',
  width
}) => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.getElementById('app').style.filter = 'blur(4px)';
  //   }

  //   return () => {
  //     document.getElementById('app').style.filter = 'none';
  //   };
  // }, [isOpen]);

  if (isOpen === false) {
    return null;
  }

  return createPortal(
    <Wrapper>
      <Background onClick={allowBackgroundClose ? close : null} />
      <Foreground isOpen={isOpen}>
        <Box width={width}>{children}</Box>
      </Foreground>
    </Wrapper>,
    document.getElementById(elementId)
  );
  // return (
  //   <Wrapper>
  //     <Background onClick={allowBackgroundClose ? close : null} />
  //     <Container>{children}</Container>
  //   </Wrapper>
  // );
};

export default Modal;
