import React, {
  useState, useEffect, useRef
} from 'react';
import styled from 'styled-components';
import Avatar from '../User/Avatar';
import VolumeControl from './VolumeControl';
import Tooltip from './Tooltip';
import PartySlot from './PartySlot';

const ParticipantContainer = styled.div`
  width: 100%;
  position: relative;
  user-select: none;
`;

const Video = styled.video`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scale(-1, 1);
  border-radius: 1rem;
  position: absolute;
  top: 0;
`;

const ShowOnHover = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: 90%;
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const Participant = ({ participant, participantsCount }) => {
  const [showHover, setShowHover] = useState(false);
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [videoActive, setVideoActive] = useState(false);
  const [audioActive, setAudioActive] = useState(false);
  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) => Array.from(trackMap.values())
    .map((publication) => publication.track)
    .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      // console.log('participant track subscribed');
      if (track.kind === 'video') {
        setVideoTracks((tracks) => [...tracks, track]);
        setVideoActive(true);
      } else if (track.kind === 'audio') {
        // console.log('audio track subscribed');
        setAudioTracks((tracks) => [...tracks, track]);
        setAudioActive(true);
      }
    };

    const trackUnsubscribed = (track) => {
      // console.log('participant track unsubscribed');
      if (track.kind === 'video') {
        setVideoTracks((tracks) => tracks.filter((v) => v !== track));
        /* Hide the associated <video> element and show an avatar image. */
        setVideoActive(false);
      } else if (track.kind === 'audio') {
        setAudioTracks((tracks) => tracks.filter((a) => a !== track));
        setAudioActive(false);
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    participant.on('trackDisabled', (track) => {
      // console.log('participant track disabled');
      if (track.kind === 'video') {
        /* Hide the associated <video> element and show an avatar image. */
        setVideoActive(false);
      }
      if (track.kind === 'audio') {
        /* Change the participant UI to show that they are not talking */
        setAudioActive(false);
      }
    });

    participant.on('trackEnabled', (track) => {
      // console.log('participant track enabled');
      if (track.kind === 'video') {
        /* Show the associated <video> element and hide an avatar image. */
        setVideoActive(true);
      }
      if (track.kind === 'audio') {
        /* Change the participant UI to show that they are talking */
        setAudioActive(true);
      }
    });

    // participant.on('trackRemoved', () => {
    //   console.log('participant track removed');
    // });

    // participant.on('trackStarted', () => {
    //   console.log('participant track started');
    // });

    // participant.on('trackAdded', () => {
    //   console.log('participant track added');
    // });

    // return () => {
    //   setVideoTracks([]);
    //   setAudioTracks([]);
    //   // console.log('return function of participant');
    //   // participant.removeAllListeners();
    // };
  }, [participant]);

  useEffect(() => {
    // console.log('Participant video change detected!');
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      // console.log('Participant video attached');
      videoTrack.attach(videoRef.current);
      setVideoActive(true);
    }

    // return () => {
    //   if (videoTrack) videoTrack.detach();
    // };
  }, [videoTracks]);

  useEffect(() => {
    // console.log('Participant audio change detected!');
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      setAudioActive(true);
    }

    // return () => {
    //   if (audioTrack) audioTrack.detach();
    // };
  }, [audioTracks]);

  return (
    <>
      <PartySlot
        name={participant.username}
        participantsCount={participantsCount}
        onMouseEnter={() => setShowHover(true)}
        onMouseLeave={() => setShowHover(false)}
      >
        <ParticipantContainer className='participant' audioActive={audioActive}>
          <Avatar src={participant.avatar} />
          <Video ref={videoRef} autoPlay style={{ opacity: videoActive ? 1 : 0 }} />
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio id={`${participant.username}-audio`} ref={audioRef} autoPlay />
          <ShowOnHover active={showHover}>
            <VolumeControl participant={participant} />
          </ShowOnHover>
        </ParticipantContainer>
      </PartySlot>

      <Tooltip
        id={participant.username}
        username={participant.username}
        avatar={participant.avatar}
      >
        <VolumeControl participant={participant} />
      </Tooltip>
    </>
  );
};

export default Participant;
