import React from 'react';
import styled from 'styled-components';
import Container from './UI/Container';
import Link from './UI/Link';

const StyledFooter = styled.div`
  padding: 2rem;
  z-index: 100;
  width: 100%;
  margin-top: 10rem;

  @media screen and (max-width: 600px) {
    margin-top: 5rem;
  }
`;

const Colophon = styled.div`
  font-size: 0.8em;
`;

const Footer = () => (
  <StyledFooter>
    <Container>
      <Colophon>
        &copy; 2022 Shomego, Inc.&nbsp;&nbsp;
        <div>
          <Link to='/privacy'>
            Privacy Policy
          </Link>
              &nbsp;|&nbsp;
          <Link to='/cookies'>
            Cookie Policy
          </Link>
              &nbsp;|&nbsp;
          <Link to='/terms'>
            Terms and Conditions
          </Link>
        </div>
      </Colophon>
    </Container>
  </StyledFooter>
);

export default Footer;
