import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useMoralisQuery } from 'react-moralis';
import Navigation from '../components/Navigation/Navigation';
import { H1 } from '../components/UI/Heading';
import { P } from '../components/UI/Text';
import Main from '../components/UI/Main';
import { ExternalLink } from '../components/UI/Link';
import Footer from '../components/Footer';
import { Colors } from '../constants';
import TipButton from '../components/Tip/TipButton';

const VenueCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)),
   ${(props) => (props.cover ? `url('${props.cover}')` : '')};
  background-size: cover;
  background-position: center top;
  padding-bottom: 5em;
  height: 100%;
  z-index: -1;
`;

const VenueDetails = styled.div`
  background: ${Colors.black};
  padding: 4rem;
  border-radius: 1.5rem;
  border: 1px solid ${Colors.grey};
  max-width: 40em;
  margin: 0 auto;
`;

const Description = styled.div`
  margin-top: 2em;
`;

const Venue = () => {
  const { username } = useParams();
  const { data, error, isLoading } = useMoralisQuery('Venue', (query) => query
    .equalTo('username', username)
    .limit(1));

  if (data?.length === 0) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (error) {
    return 'Error loading venue';
  }

  return (
    <>
      <Helmet>
        <title>
          {data[0]?.attributes?.displayName}
          {' '}
          - Shomego
        </title>
      </Helmet>
      <Navigation />

      <VenueCover cover={data[0]?.attributes?.cover} />
      <Main>
        <VenueDetails>
          <H1>
            {data[0]?.attributes?.displayName
              ? data[0]?.attributes?.displayName
              : data[0]?.attributes?.username}
          </H1>
          <P>
            <ExternalLink href={`https://etherscan.io/address/${data[0]?.attributes?.ethAddress}`}>
              {data[0]?.attributes?.ethAddress}
            </ExternalLink>
          </P>
          {isLoading
            ? <div>Loading button...</div>
            : (
              <TipButton
                address={data[0]?.attributes?.ethAddress}
                name={data[0]?.attributes?.displayName}
              />
            ) }
          <Description>
            {data[0]?.attributes?.description && <P>{data[0]?.attributes?.description}</P>}
          </Description>
          {data[0]?.attributes?.website && (
          <P>
            <ExternalLink href={data[0]?.attributes?.website}>
              Visit website
            </ExternalLink>
          </P>
          )}
        </VenueDetails>
      </Main>
      <Footer />
    </>
  );
};

export default Venue;
