import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
// import AnimatedCursor from 'react-animated-cursor';
import Colors from './constants/Colors';
import Loader from './components/UI/Loader';
import Home from './pages/Home';
import Admin from './pages/Admin';
import Show from './pages/Show';
import Venue from './pages/Venue';
import Profile from './pages/Profile';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Cookies from './pages/Cookies';
import Terms from './pages/Terms';
import Splash from './pages/Splash';
import NotFound from './pages/NotFound';

/** The point of this styling is default the nature of things to be centered in the screen */
const StyledDiv = styled.div`
  height: 100%;
`;

const Grid = styled.div`
  /** https://codepen.io/edmundojr/pen/xOYJGw */
  background:
    linear-gradient(90deg, ${Colors.deeppurple} 1.9em, transparent 1%) center,
    linear-gradient(${Colors.deeppurple} 1.9em, transparent 1%) center, ${Colors.lightgrey};
  background-size: 2em 2em;
  background-position: 1em 1em;
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: 0.2;
  z-index: -1;

  &::before {
    content: '';
    background:
    linear-gradient(90deg, black 1.9em, transparent 1%) center,
    linear-gradient(black 1.9em, transparent 1%) center, ${Colors.pink};
    background-size: 2em 2em;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    opacity: 0.15;
  }
`;

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <HelmetProvider>
      <Grid />
      <StyledDiv className='App' id='app'>
        <Helmet>
          <title>Shomego</title>
          <meta name='description' content='Social live streaming.' />
        </Helmet>
        {/* <AnimatedCursor
          innerSize={8}
          outerSize={8}
          color='193, 11, 111'
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
        /> */}
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/splash' component={Splash} />
          <Route path='/profile' component={Profile} />
          <Route path='/show/:showSlug' component={Show} />
          <Route path='/about' component={About} />
          <Route path='/admin' component={Admin} />
          <Route path='/privacy' component={Privacy} />
          <Route path='/cookies' component={Cookies} />
          <Route path='/terms' component={Terms} />
          <Route path='/@:username' component={Venue} />
          <Route component={NotFound} />
        </Switch>
      </StyledDiv>
    </HelmetProvider>
  );
};

export default App;
