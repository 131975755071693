import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import useRole from '../hooks/useRole';
import Button from '../components/UI/Button';
import Main from '../components/UI/Main';
import Modal from '../components/UI/Modal';
import Heading, { H1, H2 } from '../components/UI/Heading';
// import { P } from '../components/UI/Text';
import Navigation from '../components/Navigation/Navigation';
import CreateUpdateShow from '../components/Admin/CreateUpdateShow';
import CreateUpdateVenue from '../components/Admin/CreateUpdateVenue';
import ListVenues from '../components/Admin/ListVenues';
import ListShows from '../components/Admin/ListShows';
import NotFound from './NotFound';

const ShowContainer = styled.div`
  padding: 2em;
`;

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;

  * {
    margin-bottom: 0;
  }
`;

const HeaderLeft = styled.div`
  width: 85%;
`;

const CreateButtonContainer = styled.div`
  margin-bottom: 3em;
`;

const Admin = () => {
  const isAdmin = useRole('Administrator');
  const [updateShow, setUpdateShow] = useState(null);
  const [updateVenue, setUpdateVenue] = useState(null);
  const [toggleCreateVenue, setToggleCreateVenue] = useState(false);
  const [toggleCreateShow, setToggleCreateShow] = useState(false);
  const [toggleShowList, setToggleShowList] = useState(true);
  const [refreshList, setRefreshList] = useState(false);

  const handleUpdateShow = (show) => {
    setUpdateShow(show);
    setToggleCreateShow(true);
  };

  const handleUpdateVenue = (venue) => {
    setUpdateVenue(venue);
    setToggleCreateVenue(true);
  };

  const handleCloseModal = () => {
    setUpdateShow(null); // reset showId if there was one
    setToggleCreateShow(false); // close Modal
    setUpdateVenue(null); // reset venueId if there was one
    setToggleCreateVenue(false); // close Modal
  };

  if (!isAdmin) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <title>
          Admin - Shomego
        </title>
      </Helmet>
      {/* <Header /> */}

      <Main>
        <Modal
          isOpen={toggleCreateVenue}
          close={handleCloseModal}
          width='100rem'
          allowBackgroundClose={false}
        >
          <ShowContainer>
            <CreateUpdateVenue
              setToggleCreate={handleCloseModal}
              refreshList={refreshList}
              setRefreshList={setRefreshList}
              venue={updateVenue}
            />
          </ShowContainer>
        </Modal>

        <Modal
          isOpen={toggleCreateShow}
          close={handleCloseModal}
          width='100rem'
          allowBackgroundClose={false}
        >
          <ShowContainer>
            <CreateUpdateShow
              closeModal={handleCloseModal}
              refreshList={refreshList}
              setRefreshList={setRefreshList}
              show={updateShow}
            />
          </ShowContainer>
        </Modal>

        <ShowContainer>
          <ContainerHeader>
            <HeaderLeft>
              <Heading>
                <H1>Admin</H1>
              </Heading>
            </HeaderLeft>
            <div>
              <Button
                onClick={() => setToggleShowList(true)}
                type={toggleShowList ? 'tertiary' : 'outline'}
              >
                Shows
              </Button>
              <Button
                onClick={() => setToggleShowList(false)}
                type={toggleShowList ? 'outline' : 'tertiary'}
              >
                Venues
              </Button>
            </div>
          </ContainerHeader>

          {toggleShowList ? (
            <>
              <CreateButtonContainer>
                {/* <P>
                  Explore all upcoming and past shows, or create a new one.
                </P> */}
                <Button onClick={() => setToggleCreateShow(true)} type='secondary'>
                  Create New Show
                </Button>
              </CreateButtonContainer>
              <Heading>
                <H2>Upcoming Shows</H2>
              </Heading>
              <ListShows handleUpdate={handleUpdateShow} />
            </>
          ) : (
            <>
              <CreateButtonContainer>
                <Button onClick={() => setToggleCreateVenue(true)} type='secondary'>
                  Create New Venue
                </Button>
              </CreateButtonContainer>
              <Heading>
                <H2>Venues</H2>
              </Heading>
              <ListVenues handleUpdate={handleUpdateVenue} />
            </>
          )}
        </ShowContainer>
      </Main>

      <Navigation />
    </>
  );
};

export default Admin;
