import React from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import Colors from '../constants/Colors';
import Logo from '../assets/images/logo-glow.svg';

const StyledHeader = styled.div`
  background: ${hexToRgba(Colors.black, '0.8')};
  border-radius: 1rem;
  padding: 0.5em 0;
  margin: 1% 2%;
  z-index: 100;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  // align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled.img`
  width: 11em;
  padding: 0 1em;
`;

const Header = ({ children }) => (
  <StyledHeader>
    <StyledContainer>
      <LogoContainer>
        <StyledLogo src={Logo} alt='Shomego' />
      </LogoContainer>
      {children}
    </StyledContainer>
  </StyledHeader>
);

export default Header;
