import React, { useState, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { ShowContext } from '../../context/showContext';
import Colors from '../../constants/Colors';
import { P } from '../UI/Text';
import VideoPlayer from '../UI/VideoPlayer';
import { ReactComponent as SoundIcon } from '../../assets/icons/sound-on.svg';

const StreamContainer = styled.div`
  // min-width: 100%;
  // min-height: 100%;
  background: ${Colors.black};
  border-radius: 2rem;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  // height: 100%;

  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  border: 1px solid ${Colors.grey};

  > div {
    width: 100%;
    height: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .vjs-control-bar {
    font-size: 151%;
    background-color: rgb(0, 0, 0);
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 30vh;
  background: ${Colors.darkgrey};
  border-radius: 2rem;
  border: 1px solid ${Colors.grey};
`;

const Status = styled.div`
  font-size: 1.2em;
`;

const Flash = keyframes`
  0% {
    background: transparent;
  }

  100% {
    background: ${Colors.red};
  }
`;

const FlashingError = styled.div`
  display: inline-block;
  position: relative;
  line-height: 1;
  color: ${Colors.white};
  margin-bottom: 1rem;
  z-index: 1;
  padding: 0 0.3rem;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${Colors.red};
    opacity: 0.8;
    animation: ${Flash} 0.75s ease-in-out alternate infinite;
  }
`;

const StyledSoundIcon = styled(SoundIcon)`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

const VolumeContainer = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 1.8rem;
  right: 6rem;
  width: 10rem !important;
`;

const Stream = () => {
  const {
    showDetails,
  } = useContext(ShowContext);
  const [toggleVolume, setToggleVolume] = useState(false);
  const [volume, setVolume] = useState(1);
  const [streamError, setStreamError] = useState(false);
  const playerRef = useRef(null);

  const videoJsOptions = { // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      poster: showDetails.attributes.poster,
      src: showDetails.attributes.source,
      type: 'application/x-mpegURL'
    }]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });

    player.on('error', () => {
      setStreamError(true);
    });
  };

  const handleVolumeChange = (event) => {
    setVolume(parseFloat(event.target.value));
    playerRef.current.volume(parseFloat(event.target.value));
  };

  if (streamError) {
    return (
      <ErrorContainer>
        <Status>
          Video Status:
          {' '}
          <FlashingError>FAILED</FlashingError>
        </Status>
        <P>Please notify us about a misconfiguration for this show.</P>
      </ErrorContainer>
    );
  }

  return (
    <StreamContainer id='live-stream'>
      {showDetails?.attributes.source && (
        <>
          <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
          <StyledSoundIcon onClick={() => setToggleVolume(!toggleVolume)} />
          <VolumeContainer isOpen={toggleVolume}>
            <input
              type='range'
              value={volume}
              min={0}
              max={1}
              step={0.01}
              onChange={handleVolumeChange}
            />
          </VolumeContainer>
        </>
      )}
    </StreamContainer>
  );
};

export default Stream;
