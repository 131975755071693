import React, { createContext, useState, useCallback } from 'react';

export const ShowContext = createContext();

const ShowProvider = ({ children }) => {
  const [activeShowsList, setActiveShowsList] = useState([]);
  const [featuredShowsList, setFeaturedShowsList] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [showAudience, setShowAudience] = useState([]);
  const [time, setTime] = useState(0);

  const createShow = (data, token) => {
    console.log('Creating show...');
    return fetch(`${process.env.REACT_APP_API}/show/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.error) {
          console.log(res.error);
          return {
            error: res.error
          };
        }
        return res;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const updateShow = (data, token) => {
    console.log('Updating show...');
    return fetch(`${process.env.REACT_APP_API}/show/update/${data.id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.error) {
          console.log(res.error);
          return {
            error: res.error
          };
        }
        return res;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const deleteShow = (id, token) => {
    console.log('Deleting show...');
    return fetch(`${process.env.REACT_APP_API}/show/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res)
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const getShows = useCallback((limit) => fetch(`${process.env.REACT_APP_API}/show/list/${limit}`)
    .then((res) => res.json())
    .then((res) => {
      if (!res.error) {
        setActiveShowsList(res);
        return res;
      }
      return {
        error: res.error
      };
    })
    .catch((error) => {
      console.error(error);
    }), []);

  const getFeaturedShows = useCallback(() => {
    console.log('Getting featured shows...');
    return fetch(`${process.env.REACT_APP_API}/show/featured`)
      .then((res) => res.json())
      .then((res) => {
        if (!res.error) {
          setFeaturedShowsList(res);
          return res;
        }
        return {
          error: res.error
        };
      })
      .catch((error) => {
        console.error(error);
        return { error };
      });
  }, [setFeaturedShowsList]);

  const joinShow = async (uuid) => {
    console.log('Joining the show...');

    try {
      return await fetch(`${process.env.REACT_APP_API}/show/join`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: uuid,
          showId: showDetails.id,
          venueId: showDetails.venue_id?.id
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            console.log(res.error);
            return {
              error: res.error
            };
          }

          return res;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } catch (e) {
      console.error(e);
      return { e };
    }
  };

  /**
   * Tell our database that the user has left the show (for tracking purposes)
   */
  const leaveShow = (uuid) => {
    console.log('Leaving the show...');

    return fetch(`${process.env.REACT_APP_API}/show/leave`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: uuid,
        showId: showDetails.id,
        venueId: showDetails.venue_id?.id
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setShowDetails(null);
        if (!res.error) {
          return res;
        }
        return {
          error: res.error
        };
      })
      .catch((error) => {
        setShowDetails(null); // reset the showDetails anyway
        console.error(error);
        return { error };
      });
  };

  return (
    <ShowContext.Provider value={{
      createShow,
      updateShow,
      deleteShow,
      joinShow,
      leaveShow,
      getShows,
      getFeaturedShows,
      activeShowsList,
      featuredShowsList,
      time,
      setTime,
      showDetails,
      setShowDetails,
      showAudience,
      setShowAudience
    }}
    >
      {children}
    </ShowContext.Provider>
  );
};

export default ShowProvider;
