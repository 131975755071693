import React, {
  useState, useEffect, useContext
} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useBeforeunload } from 'react-beforeunload';
import { useMoralis, useMoralisQuery } from 'react-moralis';
import { ShowContext } from '../context/showContext';
import { PartyContext } from '../context/partyContext';
import usePubnub from '../hooks/usePubnub';
import useDisconnect from '../hooks/useDisconnect';
import useSocketConnect from '../hooks/useSocketConnect';
import Colors from '../constants/Colors';
import Error from '../components/UI/Error';
import Main from '../components/UI/Main';
import Loader from '../components/UI/Loader';
import DraggableModal from '../components/UI/DraggableModal';
// import Header from '../components/Header';
import Navigation from '../components/Navigation/Navigation';
// import NavigationItem from '../components/Navigation/Item';
import Stream from '../components/Show/Stream';
import ShowInfo from '../components/Show/ShowInfo';
import ShowStats from '../components/Show/Stats';
import Avatar from '../components/User/Avatar';
import Party from '../components/Party/Party';
import PartyList from '../components/Party/List';
import Chat from '../components/Chat';
import Button from '../components/UI/Button';
import { P } from '../components/UI/Text';
import PartySlot from '../components/Party/PartySlot';
import { ReactComponent as PartyIcon } from '../assets/icons/video-chat.svg';
// import { ReactComponent as SoundIcon } from '../assets/icons/sound-on.svg';
// import { ReactComponent as ChatIcon } from '../assets/icons/chat.svg';
// import Noise from '../assets/images/noise.png';

const ShowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Stage = styled.div`
  position: relative;
  width: 73%;

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 0 auto 2rem;
  }
`;

const SidePanel = styled.div`
  width: 27%;
  padding-left: 2rem;

  @media screen and (max-width: 1000px) {
    width: 100%;
    height: 30rem;
    margin: 0 0 2rem;
    padding-left: 0;
  }
`;

const PartyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1% 3rem;
  width: 100%;

  @media screen and (max-width: 1000px) {
    margin-top: 0rem;
  }
`;

const StyledAvatar = styled(Avatar)`
  border-radius: 1.5rem;
`;

const PartySetup = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  color: ${Colors.lightestgrey};

  &:hover {
    color: ${Colors.orange};
  }
`;

const PartyDescription = styled.div`
  font-size: 0.6em;
  text-align: center;
  padding: 0.5em;
`;

const LeaveButtonContainer = styled.div`
  margin: 0 1%;
`;

const StyledPartyIcon = styled(PartyIcon)`
  width: 5rem;
  max-width: 80%;
  height: auto;
  margin: 0 auto 0.2rem;

  &:hover {
    color: ${Colors.orange};
  }
`;

const ErrorDisplay = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChatNotConnected = styled.div`
  flex: 1;
  height: 100%;
  border: 1px solid ${Colors.grey};
  border-radius: 1em;
  padding: 2em;
`;

const ShowDetailsWrapper = styled.div`
  text-align: right;
  padding-top: 1rem;
`;

const Show = () => {
  const { showSlug } = useParams();
  const {
    setShowDetails,
    showDetails,
    // setShowAudience
    // joinShow,
    // leaveShow
  } = useContext(ShowContext);
  const {
    party,
    setParty,
    leaveParty,
    isConnecting,
    invalidPermissions
  } = useContext(PartyContext);
  const {
    user, isAuthenticated
  } = useMoralis();
  const { data, error, isLoading } = useMoralisQuery('Show', (query) => query
    .equalTo('slug', showSlug)
    // .lessThanOrEqualTo('endDate', new Date())
    .limit(1));
  const { connectSocket } = useSocketConnect(showSlug);
  const [toggleVolume, setToggleVolume] = useState(false);
  const [togglePartyList, setTogglePartyList] = useState(false);
  const [permissionsToggle, setPermissionsToggle] = useState(false);
  const [attendeeCount, setAttendeeCount] = useState(0);
  const { pubnub, subscribe } = usePubnub(showSlug, setAttendeeCount);

  const isShowOpen = (startDate, endDate) => {
    const hasShowStarted = new Date() >= new Date(startDate).getTime();
    const hasShowEnded = new Date() >= new Date(endDate).getTime();
    return hasShowStarted && !hasShowEnded;
  };

  /**
   * We need both state and variable for the socket to be able to use it in another component
   * as props (the state) and to disconnect it on leave (the variable)
   */
  const { disconnect } = useDisconnect(showSlug);

  /** We need to reset a show in case it didn't reset from a previous show */
  useEffect(() => {
    if (data.length > 0) {
      setShowDetails(data[0]);
    } else {
      setShowDetails(null);
    }
  }, [data, setShowDetails]);

  useEffect(() => {
    /** Track whether we've joined or not, so the return doesn't fire prematurely */
    let joined = false;

    if (!joined
      && showDetails
      && isShowOpen(showDetails?.attributes?.startDate, showDetails?.attributes?.endDate)
      && isAuthenticated) {
      // const joinedShow = joinShow(user.id);

      // if (joinedShow) {
      //   joined = true;
      //   subscribe();
      //   connectSocket();
      // }
      joined = true;
      subscribe();
      connectSocket();
    }

    return () => {
      if (joined
        && showDetails
        // && isShowOpen(showDetails?.attributes?.startDate, showDetails?.attributes?.endDate)
        && isAuthenticated) {
        // leaveShow(user.id);
        /** Reset any party states */
        setParty(null);
        disconnect();
      }
    };
  }, [
    // user,
    // joinShow,
    // leaveShow,
    connectSocket,
    disconnect,
    subscribe,
    setParty,
    showDetails,
    // isShowOpen,
    isAuthenticated
  ]);

  useBeforeunload(() => {
    // leaveShow(user.id);
    /** Reset any party states */
    setParty(null);
    disconnect();
  });

  const handleVolume = () => {
    setToggleVolume(!toggleVolume);
  };

  const handlePartyList = () => {
    setTogglePartyList(!togglePartyList);
  };

  const handlePermissions = () => {
    setPermissionsToggle(!permissionsToggle);
  };

  useEffect(() => {
    if (invalidPermissions) setPermissionsToggle(true);
  }, [invalidPermissions]);

  if (error) {
    return (
      <ErrorDisplay>
        <Error label='ERROR' status={error} />
      </ErrorDisplay>
    );
  }

  if (isLoading || !showDetails) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        {showDetails.attributes.name && (
        <title>
          {showDetails.attributes.name}
          {' '}
          - Shomego
        </title>
        )}
        {showDetails.attributes.description
            && <meta name='description' content={showDetails.attributes.description} />}

        {showDetails.attributes.name
            && <meta property='og:title' content={showDetails.attributes.name} />}
        {showDetails.attributes.description
              && <meta property='og:description' content={showDetails.attributes.description} />}
        {showDetails.attributes.poster
            && (
              <>
                <meta property='og:image' content={showDetails.attributes.poster} />
                <meta name='twitter:image:alt' content='Show poster' />
              </>
            )}
        {showDetails.attributes.slug
            && <meta property='og:url' content={`https://shomego.com/show/${showDetails.attributes.slug}`} />}
      </Helmet>

      <Navigation />

      <Main>
        {isShowOpen(
          showDetails.attributes.startDate,
          showDetails.attributes.endDate
        ) ? (
          <>
            <ShowInfo />

            <ShowContainer>
              <Stage>
                <Stream
                  focused
                  toggleVolume={toggleVolume}
                  handleVolume={handleVolume}
                />

                <ShowDetailsWrapper>
                  <ShowStats attendeeCount={attendeeCount} />
                </ShowDetailsWrapper>
              </Stage>

              <SidePanel>
                {user && pubnub ? (
                  <Chat pubnub={pubnub} />
                )
                  : (
                    <ChatNotConnected>
                      <P>Connect to party and chat with others at the show.</P>
                    </ChatNotConnected>
                  )}
              </SidePanel>
            </ShowContainer>

            <PartyContainer>
              {isAuthenticated && (
                <>
                  {(!party || isConnecting) && (
                  <>
                    <PartySlot name={user.get('displayName') || user.get('ethAddress').substring(0, 5)}>
                      <StyledAvatar src={user.get('avatar')} />
                    </PartySlot>

                    <PartySlot>
                      <PartySetup onClick={handlePartyList}>
                        <StyledPartyIcon />
                        <PartyDescription>Setup or join a party</PartyDescription>
                      </PartySetup>
                    </PartySlot>
                  </>
                  )}

                  {party && party.token
              && (
              <>
                <Party />
                <LeaveButtonContainer>
                  <Button type='secondary' onClick={() => leaveParty()}>Leave Party</Button>
                </LeaveButtonContainer>
              </>
              )}

                  <PartyList
                    togglePartyList={togglePartyList}
                    handleTogglePartyList={handlePartyList}
                  />

                  <DraggableModal
                    isOpen={permissionsToggle}
                    close={handlePermissions}
                    title='Permissions'
                  >
                    Error in connecting to party
                  </DraggableModal>
                </>
              )}
            </PartyContainer>
          </>
          ) : (
            <ShowInfo expanded />
          )}
      </Main>
    </>
  );
};

export default Show;
