import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { MoralisProvider } from 'react-moralis';
import Auth0ProviderWithHistory from './utils/auth0-provider-with-history';
import VenueProvider from './context/venueContext';
import ShowProvider from './context/showContext';
import PartyProvider from './context/partyContext';
import SocketProvider from './context/socketContext';
import ChatProvider from './context/chatContext';
import GlobalStyle from './constants/GlobalStyle';
import App from './App';

const appId = process.env.REACT_APP_MORALIS_APPID;
const serverUrl = process.env.REACT_APP_MORALIS_SERVERURL;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <GlobalStyle />
      <MoralisProvider appId={appId} serverUrl={serverUrl}>
        <SocketProvider>
          <VenueProvider>
            <ShowProvider>
              <PartyProvider>
                <ChatProvider>
                  <Auth0ProviderWithHistory>
                    <App />
                  </Auth0ProviderWithHistory>
                </ChatProvider>
              </PartyProvider>
            </ShowProvider>
          </VenueProvider>
        </SocketProvider>
      </MoralisProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
